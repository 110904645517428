import { Button, makeStyles } from "@material-ui/core";
import React from "react";
import { useBurger } from "../../context/BurgerContext";

const Burger = () => {
  const { isActive, setActive } = useBurger();

  const handleToggle = () => {
    setActive(!isActive);
  };

  const classes = useStyles();
  return (
    <Button
      disableRipple
      disableTouchRipple
      classes={{ root: classes.root }}
      id="myDiv"
      className={isActive ? "hamburger hamburger--collapse is-active" : "hamburger hamburger--collapse"}
      onClick={handleToggle}
      style={{
        minWidth: "0px",
      }}
    >
      <span className="hamburger-box">
        <span className="hamburger-inner"></span>
      </span>
    </Button>
  );
};

const useStyles = makeStyles({
  root: {
    padding: "7px 2px",
  },
});

export default Burger;
