import React from "react";
import styled from "styled-components";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import LocationIcon from "@material-ui/icons/LocationOn";
import EmailIcon from "@material-ui/icons/AlternateEmail";
import SignUpIcon from "@material-ui/icons/Email";
import { useTranslation } from "react-i18next/";
import { AppStore, GooglePlay } from "../styles/mobileStoreButtons";

const Footer = () => {
  const { t } = useTranslation();
  const MailTo = `mailto:coupons@coupons.is?subject=${t("FooterIncMail")}`;

  return (
    <FooterWrapper>
      <DivContainer>
        <DivStyled>
          <ul style={{ listStyleType: "none" }}>
            <div style={{ fontWeight: "bold", marginBottom: "10px" }}>{t("FooterInfo")}</div>
            <A2>
              <LocationIcon />
              <IconTitle>Katrínartún 2, 18. hæð 105 Reykjavík</IconTitle>
            </A2>
            <A href="mailto:coupons@coupons.is">
              <EmailIcon />
              <IconTitle>coupons@coupons.is</IconTitle>
            </A>
          </ul>
        </DivStyled>
        <DivStyled>
          <ul style={{ listStyleType: "none" }}>
            <div style={{ fontWeight: "bold", marginBottom: "10px" }}>{t("FooterInc")}</div>
            <A href={MailTo}>
              <SignUpIcon />
              <IconTitle>{t("FooterIncSub")}</IconTitle>
            </A>
          </ul>
        </DivStyled>
        <DivStyled>
          <ul style={{ listStyleType: "none" }}>
            <div style={{ fontWeight: "bold", marginBottom: "10px" }}>{t("FooterSocial")}</div>
            <A href="https://www.facebook.com/icelandiccoupons">
              <FacebookIcon />
              <IconTitle>Facebook</IconTitle>
            </A>
            <A href="https://www.instagram.com/icelandiccoupons/">
              <InstagramIcon />
              <IconTitle>Instagram</IconTitle>
            </A>
          </ul>
        </DivStyled>
        <DivStyled>
          <ul style={{ listStyleType: "none" }}>
            <div style={{ fontWeight: "bold", marginBottom: "10px" }}>{t("FooterApp")}</div>
            <li>
              <a href="https://apps.apple.com/us/app/icelandic-coupons/id1317769121">
                <AppStore />
              </a>
            </li>
            <li style={{ marginTop: "8px" }}>
              <a href="https://play.google.com/store/apps/details?id=com.loftfar.coupons">
                <GooglePlay />
              </a>
            </li>
          </ul>
        </DivStyled>
      </DivContainer>
      <Copyright>{t("FooterRights")}</Copyright>
    </FooterWrapper>
  );
};

const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #1c1c1c;
  color: white;
  font-size: 14px;
  padding-top: 16px;
  margin-top: 32px;
  position: relative;

  @media screen and (min-width: 768px) {
    padding-top: 20px;
    height: 344px;
    font-size: 16px;
    margin-top: 100px;
  }
`;
const DivContainer = styled.div`
  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 1352px;
    justify-content: space-between;
  }
`;

const DivStyled = styled.div`
  display: flex;
  flex: 1;

  @media screen and (min-width: 768px) {
  }
`;

const A = styled.a`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  text-decoration: none;
  color: #c9c9c9;
`;

const A2 = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  text-decoration: none;
  color: #c9c9c9;
`;

const IconTitle = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
`;

const Copyright = styled.div`
  display: flex;
  font-size: 12px;
  align-items: flex-end;
  flex: 1;
  margin-bottom: 16px;
  color: #999999;
`;

export default Footer;
