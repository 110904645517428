import styled from "styled-components";

export const AppStore = styled.img.attrs({
  src: "https://couponswebapp.s3.eu-west-2.amazonaws.com/vefsida-peturaron/apple.png",
})`
  width: 150px;
  @media screen and (min-width: 768px) {
    width: 150px;
  }
`;

// https://play.google.com/intl/en_us/badges/images/apps/en-play-badge.png
export const GooglePlay = styled.img.attrs({
  src: "https://couponswebapp.s3.eu-west-2.amazonaws.com/vefsida-peturaron/google.png",
})`
  width: 171px;

  @media screen and (min-width: 768px) {
    width: 171px;
  }
`;

export const StoreButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  position: relative;
  justify-content: center;
  margin-top: 16px;
  margin-bottom: 24px;

  @media screen and (max-width: 768px) {
    gap: 10px;
  }
`;
