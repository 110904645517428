import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";
import {
  CompanyViewModel,
  CouponsSource,
  CouponsWhenFilter,
  CouponViewModel,
  useCompaniesQuery,
  useCouponsQuery,
} from "../types/graphTypes";

interface IContext {
  stateId: number;
  setStateId: React.Dispatch<React.SetStateAction<number>>;
  couponsData?: (CouponViewModel & { company?: CompanyViewModel })[];
  couponsLoading: boolean;
  companiesLoading: boolean;
  couponsError?: unknown;
  companiesError?: unknown;
}

const CouponContext = createContext<IContext>({
  stateId: 0,
  setStateId: () => {},
  couponsData: [],
  couponsLoading: false,
  companiesLoading: false,
  couponsError: undefined,
  companiesError: undefined,
});

export const useCoupon = () => {
  return useContext(CouponContext);
};

const CouponProvider = ({ children }: { children: ReactNode }) => {
  const [stateId, setStateId] = useState<number>(0);

  const {
    data: coupons,
    isLoading: couponsLoading,
    error: couponsError,
  } = useCouponsQuery({
    query: {
      whenFilter: CouponsWhenFilter.All,
      source: CouponsSource.Coupons,
    },
  });

  const { data: companies, isLoading: companiesLoading, error: companiesError } = useCompaniesQuery();

  const [couponsData, setCouponsData] = useState<(CouponViewModel & { company?: CompanyViewModel })[]>([]);

  useEffect(() => {
    if (!coupons || !companies) return;

    const enrichedCoupons = coupons.coupons.map((coupon) => {
      const company = companies.companies.find((company) => company.id === coupon.companyId);
      return { ...coupon, company };
    });

    setCouponsData(enrichedCoupons as (CouponViewModel & { company?: CompanyViewModel })[]);
  }, [coupons, companies]);

  return (
    <CouponContext.Provider
      value={{
        stateId,
        setStateId,
        couponsData,
        couponsLoading,
        companiesLoading,
        couponsError,
        companiesError,
      }}
    >
      {children}
    </CouponContext.Provider>
  );
};

export default CouponProvider;
