import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
const resources = {
  en: {
    translation: {
      //. React Helmet
      HelmetAppTitle: "Coupons.is - Enjoy Iceland for a much lower price by using discounts",
      HelmetAppContent: "Enjoy Iceland for a much lower price by using The Icelandic Coupons app",

      HelmetBrochureTitle: "Coupons.is - The Icelandic Coupons Brochure",
      HelmetBrochureContent: "Enjoy Iceland for a much lower price by using coupons",

      HelmetBrochureSubTitle: "Coupons.is - Where To Buy?",
      HelmetBrochureSubContent: "You can buy the Icelandic Coupons brochure at the following locations",

      HelmetCouponsTitle: "Coupons.is - Our offers",
      HelmetCouponsContent: "Our offers - Icelandic Coupons",

      HelmetAboutTitle: "Coupons.is - Who we are",
      HelmetAboutContent: "About Us - Icelandic Coupons",

      //. AppPage.tsx
      AppHome: "Save money with the new and only Coupons App in Iceland. Download now!",
      AppMoreInfo: "View the Coupons app various offers",
      AppMoreInfoText:
        "It updates continuously throughout each month, it’s like a fresh coupon buffet at your fingertips!",
      AppMoreInfoButton: "View",
      AppHomeInfo: "Save money with the new and only",
      AppHomeInfoSub: "Coupons App in Iceland!",
      AppFeatured: "Featured coupons",
      AppMore: "More coupons",

      //. AboutPage.tsx
      AboutPageTopText: "About us",
      AboutPageTopTextInfo: "Who we are",
      AboutUs:
        "Icelandic Coupons is a family run business established in 2015. Enjoying Iceland can be really expensive but our goal has been to make it more affordable so you don‘t have to empty your bank account for a trip to our beautiful island. Now you can enjoy top restaurants, bars, cafés, shopping, adventures & entertainment in Iceland for a much lower price by using the discounts provided. You can either buy the brochure or download the app.",
      AboutBrochure:
        "We have dedicated our time to find the most popular places for you to explore while in Iceland so you don’t have to waste your precious time wondering if you're choosing the right one’s during your stay. Furthermore, by using our app or brochure you get discounts every single day, also on weekends. To view our latest brochure ",
      AboutAppTitle: "The Coupons App",
      AboutBrochureTitle: "The Brochure",

      //. BrochurePage.tsx
      BrochureMain: "Save money all around Iceland with our Brochure, you can save 30.000 ISK every day!",
      BrochureMainSub: "Explore Iceland, the brochure got you covered.",
      BrochureMainSubSub: "Discounts all around Iceland.",

      BrochureMainMobile: "Explore, the brochure got you covered.",
      BrochureMainMobileSub: "You can save 30.000 ISK every day.",
      BrochureMainMobileSubSub: "Discounts all around Iceland.",

      BrochureOfferButton: "View all offers",
      BrochureInfoButton: "Where to buy?",

      //. BrochureSubPage.tsx
      BrochureSubTitle: "The Brochure",
      BrochureSubWhere: "Where to buy?",

      BrochureSubIcelandair:
        "Proud to say that Icelandair sells the Icelandic Coupons brochure on board their in-flight store, Saga Shop. When buying through Saga Shop, you get the brochure, access to the app and a Vodafone sim-card ready for use during your stay in Iceland. The whole package for only ISK 1000 (approx $10).",
      BrochureSubEuropcar:
        "Europcar car rental is the largest car rental company in Iceland and plays a big role in welcoming tourists to Iceland. One of the key thing when renting a car is to get the actual key and the Icelandic Coupons brochure. Enjoy Iceland for less!",
      BrochureSubIcewear:
        "You can get warm and affordable clothing and accessories in Icewear stores. You get 15% discount with the Icelandic coupons offer as well as buying the brochure in Icewear stores.",

      //. CouponsPage.tsx
      CouponsPageTopTextInfo: "Our offers",

      //. CustomizedDialog.tsx
      DineoutBookTable: "Book a table",
      VisitWebsite: "Visit website",
      ViewOnMaps: "View on maps",
      GetAppMessage:
        "To redeem this exclusive coupon and unlock a world of savings at your favorite restaurants, hotels, and more, simply download our app. It's your golden ticket to not just this deal but a whole world of discounts and special offers tailored just for you.",

      //. Header.tsx
      HeaderAppText: "App",
      HeaderBrochureText: "Brochure",
      HeaderBrochureTextSub: "The Brochure",
      HeaderBrochureTextSubSub: "Where to buy?",
      HeaderCouponsText: "Offers",
      HeaderAboutText: "About Us",

      //. Footer.tsx
      FooterInfo: "Info",
      FooterInc: "Companies join us",
      FooterIncSub: "Sign up via email",
      FooterIncMail: "I want to join Icelandic Coupons",
      FooterSocial: "Find us on",
      FooterApp: "Download our app",
      FooterRights: "Copyright © 2024 Dineout ehf. All rights reserved.",
    },
  },
  is: {
    translation: {
      //. React Helmet
      HelmetAppTitle: "Coupons.is - Sparaðu með afsláttar appi Icelandic Coupons",
      HelmetAppContent: "Sparaðu með afsláttar appi Icelandic Coupons einfaldlega með að smella því í símann! ",

      HelmetBrochureTitle: "Coupons.is - Bæklingurinn",
      HelmetBrochureContent: "Ýmis afslættir á matsölustaði, bari, skemmtun, verslun o.fl.",

      HelmetBrochureSubTitle: "Coupons.is - Helstu sölustaðir",
      HelmetBrochureSubContent: "Helstu sölustaðir Icelandic Coupons",

      HelmetCouponsTitle: "Coupons.is - Okkar tilboð",
      HelmetCouponsContent: "Okkar tilboð - Icelandic Coupons",

      HelmetAboutTitle: "Coupons.is - Hver við erum",
      HelmetAboutContent: "Um okkur - Icelandic Coupons",

      //. AppPage.tsx
      AppHome: "Sparaðu með afsláttar appi Icelandic Coupons. Smelltu því í símann hér!",
      AppMoreInfo: "Skoða ýmis tilboð Coupons Appsins",
      AppMoreInfoText: "Afslættirnir uppfærast í hverjum mánuði, því alltaf eitthvað nýtt og spennandi til að prófa!",
      AppMoreInfoButton: "Skoða",
      AppHomeInfo: "Byrjaðu að spara með Coupons Appinu",
      AppHomeInfoSub: "Smelltu því í símann hér!",
      AppFeatured: "Vinsæl Tilboð",
      AppMore: "Fleiri tilboð",

      //. AboutPage.tsx
      AboutPageTopText: "Um okkur",
      AboutPageTopTextInfo: "Hver við erum",
      AboutUs:
        "Icelandic Coupons var stofnað árið 2015 og sérhæfir sig í að sækja bestu afslætti sem völ er á hjá vinsælustu stöðum Íslands bæði í mat, drykk, skemmtun, afþreyingu, verslun og þjónustu. Afslættina er hægt að nota með bækling eða á rafrænu formi í gegnum Icelandic Coupons appið.",
      AboutBrochure:
        "Icelandic Coupons bæklingurinn kemur út tvisvar á ári, sumar- og vetrarútgáfa (1. apríl og 1. október) og hefur verið gefinn út frá árinu 2015. Hann inniheldur rúmlega 100 afsláttarmiða á matsölustaði, bari, skemmtun, verslun o.fl. Bæklingurinn er meðal annars afhendur ferðamönnum sem leigja bíl hjá Europcar og dreift á öll helstu hótel á Íslandi. Þeir sem verða sér út um bæklinginn nota hann með því að rífa afsláttarmiðann úr og rétta afgreiðslufólki við greiðslu. Til að skoða okkar nýjasta bækling ",
      AboutAppTitle: "Coupons Appið",
      AboutBrochureTitle: "Bæklingurinn",

      //. BrochurePage.tsx
      BrochureMain: "Sparaðu með afsláttar bækling Icelandic Coupons, þú getur sparað 30.000 ISK alla daga!",
      BrochureMainSub: "Ýmis afslættir á matsölustaði, bari, skemmtun, verslun o.fl.",
      BrochureMainSubSub: "Njóttu Íslands með bæklinginn í framsætinu!",

      BrochureMainMobile: "Matsölustaðir, barir, skemmtun, verslun o.fl.",
      BrochureMainMobileSub: "Sparaðu með afsláttar bækling ",
      BrochureMainMobileSubSub: "Icelandic Coupons!",

      BrochureOfferButton: "Skoða öll tilboð",
      BrochureInfoButton: "Helstu sölustaðir",

      //. BrochureSubPage.tsx
      BrochureSubTitle: "Bæklingurinn",
      BrochureSubWhere: "Helstu sölustaðir",

      BrochureSubIcelandair:
        "Saga Shop er verslun flugfélags Icelandair um borð þar sem í boði er fjölbreytt úrval framandi vörum á tollfrjálsu verði. Þegar þú kaupir bækling Icelandic Coupons í gegnum Saga Shop færðu m.a. bæklinginn, fullan aðgang að appinu okkar ásamt Vodafone sim-korti tilbúið til notkunar!",
      BrochureSubEuropcar: "Bæklingurinn er til sölu hjá bílaleigum Europcar (Akureyri, Reykjavík & Keflavík).",
      BrochureSubIcewear: "Bæklingurinn er til sölu í verslunum Icewear á höfuðborgarsvæðinu.",

      //. CouponsPage.tsx
      CouponsPageTopTextInfo: "Okkar tilboð",

      //. CustomizedDialog.tsx
      DineoutBookTable: "Bóka borð",
      VisitWebsite: "Skoða vefsíðu",
      ViewOnMaps: "Skoða á korti",
      GetAppMessage:
        "Til þess að nýta þennan afslátt og fá aðgang að fjölbreyttum afsláttum á þínum uppáhalds matsölustöðum, hótelum og fleiru, þá þarf að hlaða niður Icelandic Coupons appinu. Þar finnur þú fjölbreytt úrval tilboða sem eru sérsniðin fyrir þig.",

      //. Header.tsx
      HeaderAppText: "Appið",
      HeaderBrochureText: "Bæklingurinn",
      HeaderBrochureTextSub: "Nýjasti Bæklingur",
      HeaderBrochureTextSubSub: "Helstu Sölustaðir",
      HeaderCouponsText: "Tilboð",
      HeaderAboutText: "Um Okkur",

      //. Footer.tsx
      FooterInfo: "Upplýsingar",
      FooterInc: "Fyrirtæki",
      FooterIncSub: "Taka þátt í Coupons",
      FooterIncMail: "Ég vil taka þátt í Coupons",
      FooterSocial: "Finndu okkur á",
      FooterApp: "Appið okkar",
      FooterRights: "Höfundaréttur © 2024 Dineout ehf. Allur réttur áskilinn.",
    },
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: "is",
    // fallbackLng: ["en", "is"],
    whitelist: ["en", "is"],
    // preload: ["en", "is"],
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
