import React, { useEffect } from "react";
import WaveBackground from "../components/App/WaveBackground";
import styled from "styled-components";
import { Button, makeStyles, Typography } from "@material-ui/core";
import MediaQuery from "react-responsive";
import { moveInTop } from "../styles/keyframes";
import { useTranslation } from "react-i18next/";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

const BrochurePage = () => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>{t("HelmetBrochureTitle")}</title>
        <meta name="description" content={t("HelmetBrochureContent")} />
      </Helmet>

      <WaveBackground c1="#4682e2" c2="#7701fd" />
      <ParentDiv>
        <MainContent>
          <BrochureImage
            imgurl={
              i18n.language === "is"
                ? "https://res.cloudinary.com/dineout/image/upload/v1728316631/couponsweb/yquqksdspvdyija5ektu.png"
                : "https://res.cloudinary.com/dineout/image/upload/v1728316631/couponsweb/dbxntvzhhdyu5lxpf1ec.png"
            }
          >
            <a
              role="button"
              target="_"
              href="https://issuu.com/icelandiccoupons/docs/icecoup_17vef"
              style={{ height: "100%", width: "100%" }}
            />
          </BrochureImage>
        </MainContent>

        <SubContent>
          <MediaQuery query="(min-width: 916px)">
            <Typography variant="h3" style={{ marginBottom: "8px" }}>
              Icelandic Coupons
            </Typography>
            <Typography variant="h5" color="textSecondary">
              {t("BrochureMain")}
            </Typography>
            <Typography variant="h5" color="textSecondary">
              {t("BrochureMainSub")}
            </Typography>
            {/* <Typography variant="h5" color="textSecondary">
              {t("BrochureMainSubSub")}
            </Typography> */}
          </MediaQuery>

          <MediaQuery query="(max-width: 915px)">
            <Typography variant="h4" style={{ marginBottom: "8px" }}>
              Icelandic Coupons
            </Typography>
            <Typography variant="h6" color="textSecondary">
              {t("BrochureMainMobile")}
            </Typography>
            <Typography variant="h6" color="textSecondary">
              {t("BrochureMainMobileSub")}
            </Typography>
            <Typography variant="h6" color="textSecondary">
              {t("BrochureMainMobileSubSub")}
            </Typography>
          </MediaQuery>
          <div>
            <Button
              onClick={() => history.push({ pathname: "/coupons", search: history.location.search })}
              variant="contained"
              color="primary"
              className={classes.root2}
            >
              {t("BrochureOfferButton")}
            </Button>
            {/* <Button
              onClick={() => history.push({ pathname: "/brochure/access", search: history.location.search })}
              variant="contained"
              color="primary"
              className={classes.root}
            >
              {t("BrochureInfoButton")}
            </Button> */}
          </div>
        </SubContent>
      </ParentDiv>
    </>
  );
};

const useStyles = makeStyles({
  root: {
    textTransform: "none",
    color: "white",
    fontSize: "20px",
    padding: "10px 18px",
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    borderRadius: 50,
    border: "none",
    outline: "none",
    marginTop: "20px",
    marginBottom: "20px",
    ["@media (min-width:780px)"]: {
      padding: "10px 58px",
    },
    ["@media (max-width:320px)"]: {
      padding: "10px 8px",
    },
  },
  root2: {
    textTransform: "none",
    color: "white",
    fontSize: "20px",
    padding: "10px 18px",
    background: "linear-gradient(45deg, #FF8E53 30%, #FE6B8B 90%)",
    borderRadius: 50,
    border: "none",
    outline: "none",
    marginTop: "20px",
    marginBottom: "20px",
    marginRight: "12px",
    ["@media (min-width:780px)"]: {
      padding: "10px 58px",
    },
    ["@media (max-width:320px)"]: {
      padding: "10px 8px",
    },
  },
});

const ParentDiv = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100vh;
`;

const MainContent = styled.div`
  display: flex;
  justify-content: center;
  flex: 4;
  padding: 100px 24px 24px 24px;
  @media screen and (min-width: 768px) {
    flex: 7;
  }
`;

const SubContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 3;
  padding-bottom: 35px;
  @media screen and (min-width: 768px) {
    padding-bottom: 10px;
    flex: 2;
  }
`;

const BrochureImage = styled.div<{ imgurl: string }>`
  display: flex;
  background-image: ${(props) => {
    return `url(${props.imgurl})`;
  }};
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 100%;
  width: 600px;
  animation: ${moveInTop} 0.8s;
`;

export default BrochurePage;
