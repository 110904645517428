import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
// import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useCoupon } from "../context/CouponContext";
import { useDialog } from "../context/DialogContext";
import { OfferWrapper } from "./App/Coupons/CouponListItem";
import CloseIcon from "@material-ui/icons/Close";
import { useTranslation } from "react-i18next/";
import { companyNameString, discountString } from "../utils/stringUtils";
import { AppStore, GooglePlay, StoreButtons } from "../styles/mobileStoreButtons";

const CustomizedDialog = () => {
  const { t, i18n } = useTranslation();
  // const history = useHistory();
  const { dialog, setDialog } = useDialog();
  const { couponsData, couponsLoading, companiesLoading, couponsError, stateId } = useCoupon();
  const coupon = couponsData?.find((coupon) => coupon.id === stateId);
  const classes = useStyles();
  const dineoutUrl = coupon?.company?.bookingUrl || "";
  const companyName = companyNameString(coupon?.company?.languages || []);

  const handleClose = () => {
    setDialog(!dialog);
    // history.goBack();
  };

  const dialogContent = (
    <>
      <IconButton className={classes.closeButton} onClick={handleClose}>
        <CloseIcon />
      </IconButton>
      <img src={coupon?.photo?.url} style={{ maxWidth: "100%", maxHeight: "100%" }} />
      <OfferWrapper>
        <OfferStyled>
          <OfferText>{discountString(coupon?.discount)}</OfferText>
        </OfferStyled>
      </OfferWrapper>
      <DineoutWrapper>
        <DialogTitle style={{ fontSize: "24px" }}>{companyName}</DialogTitle>
      </DineoutWrapper>
      <DialogContent style={{ display: "flex", flexDirection: "column", flex: 1 }}>
        <DialogContentText id="alert-dialog-title" color="textPrimary">
          {coupon?.languages.find((item) => item.language === i18n.language)?.title}
        </DialogContentText>
        <DialogContentText id="alert-dialog-description">{t("GetAppMessage")}</DialogContentText>
        <StoreButtons>
          <a href="https://apps.apple.com/us/app/icelandic-coupons/id1317769121">
            <AppStore />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.loftfar.coupons">
            <GooglePlay />
          </a>
        </StoreButtons>
      </DialogContent>
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end", margin: "8px 24px" }}>
        {dineoutUrl ? (
          <a target="_blank" href={dineoutUrl}>
            <Button variant="contained" className={classes.button}>
              {t("DineoutBookTable")}
            </Button>
          </a>
        ) : null}
        <Divider style={{ marginTop: "20px", marginBottom: "6px" }} />
        <InfoDiv>
          <Typography color="textSecondary">
            <a target="_" href={coupon?.company?.website || ""}>
              {t("VisitWebsite")}
            </a>
          </Typography>
          <Typography color="textSecondary">
            <a target="_" href={`http://maps.google.com/?q=${coupon?.companyName.split(" ").join("+")}`}>
              {t("ViewOnMaps")}
            </a>
          </Typography>
        </InfoDiv>
      </div>
    </>
  );

  if (couponsLoading) {
    return (
      <Dialog open={dialog} onClose={handleClose} classes={{ paper: classes.paper }}>
        <IconButton className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        <DialogContent style={{ display: "flex", justifyContent: "center", alignItems: "center" }}></DialogContent>
        <LinearProgress classes={{ root: classes.progress }} />
      </Dialog>
    );
  }

  if (couponsError) {
    return (
      <Dialog open={dialog} onClose={handleClose} classes={{ paper: classes.paper }}>
        <IconButton className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        <DialogContent style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Typography variant="h5">Error loading, please try again later.</Typography>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open={dialog} onClose={handleClose} classes={{ paper: classes.paper }}>
      {!couponsLoading && !companiesLoading && stateId != 0 && dialogContent}
    </Dialog>
  );
};

const useStyles = makeStyles({
  progress: {
    width: "700px",
    height: "6px",
  },
  paper: {
    maxWidth: "700px",
    ["@media (min-width: 768px)"]: {
      width: "700px",
      // width: "700px",
      height: "625px",
    },
  },
  button: {
    display: "flex",
    width: "100%",
    textTransform: "none",
    color: "white",
    fontSize: "16px",
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
  },
  closeButton: {
    position: "absolute",
    color: "grey",
    zIndex: 1,
  },
});

const InfoDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DineoutWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  margin-top: 10px;
  color: #717171;
`;

const OfferStyled = styled.div`
  width: 80px;
  height: 80px;
  border-style: solid;
  border-width: 0px 80px 80px 0;
  border-color: #29697c #29697c transparent transparent;
  color: white;
`;

const OfferText = styled.div`
  position: relative;
  font-size: 20px;
  right: -41px;
  font-weight: bold;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
`;

export default CustomizedDialog;
