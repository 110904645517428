import { Button, Card, CardActionArea, CardContent, CardMedia, makeStyles, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useCoupon } from "../../../context/CouponContext";
import { useDialog } from "../../../context/DialogContext";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { useTranslation } from "react-i18next/";
import { companyNameString, discountString, locationsString } from "../../../utils/stringUtils";
import { CompanyViewModel, CouponViewModel } from "../../../types/graphTypes";

const CouponListItem = ({ coupon }: { coupon: CouponViewModel & { company?: CompanyViewModel } }) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { setDialog } = useDialog();
  const { setStateId } = useCoupon();

  // Used to disable hover and ripple effect on card when clicking nested Book a table button
  const [book, setBook] = useState(false);

  const handleClick = () => {
    setDialog(true);
    // if (history.location.pathname === "/") {
    //   history.push({ pathname: `/${coupon?._id}`, search: history.location.search });
    // } else if (history.location.pathname === "/coupons") {
    //   history.push({ pathname: `/coupons/${coupon?._id}`, search: history.location.search });
    // }
    if (coupon?.id) {
      setStateId(coupon.id);
    }
  };

  const cardContent = (
    <>
      <OfferWrapper>
        <OfferStyled>
          <OfferText>{discountString(coupon.discount)}</OfferText>
        </OfferStyled>
      </OfferWrapper>
      <CardMedia
        className={!history.location.pathname.includes("/coupons") ? classes.media : classes.media2}
        image={coupon.photo?.url}
      />
      <CardContent style={{ height: "100px" }}>
        <Typography gutterBottom style={{ fontSize: "23px" }} component="h2">
          {companyNameString(coupon?.company?.languages || [])}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {coupon.languages.find((item) => item.language === i18n.language)?.title}
        </Typography>
      </CardContent>

      <DineoutWrapper>
        <Typography color="textSecondary" component="p" style={{ fontSize: "14px" }}>
          <LocationOnIcon style={{ fontSize: "18px", position: "absolute", marginTop: "1.3px" }} />
          <span style={{ marginLeft: "20px" }}>{locationsString(coupon.locations)}</span>
        </Typography>
        {coupon?.company?.bookingUrl ? (
          <a
            target="_blank"
            href={coupon.company.bookingUrl}
            onClick={(e) => e.stopPropagation()}
            onMouseOver={() => setBook(true)}
            onMouseOut={() => setBook(false)}
            onTouchStart={() => setBook(true)}
            onTouchEnd={() => setBook(false)}
          >
            <Button variant="contained" className={classes.button}>
              {t("DineoutBookTable")}
            </Button>
          </a>
        ) : null}
      </DineoutWrapper>
    </>
  );

  return (
    <Card className={!history.location.pathname.includes("/coupons") ? classes.root : classes.root2}>
      {book ? (
        <CardActionArea
          onClick={handleClick}
          disableRipple
          classes={{ root: classes.action, focusHighlight: classes.focusHighlight }}
        >
          {cardContent}
        </CardActionArea>
      ) : (
        <CardActionArea onClick={handleClick}>{cardContent}</CardActionArea>
      )}
    </Card>
  );
};

const OfferStyled = styled.div`
  width: 70px;
  height: 70px;
  border-style: solid;
  border-width: 0 70px 70px 0;
  border-color: #29697c #29697c transparent transparent;
  color: white;
`;

const OfferText = styled.div`
  position: relative;
  font-size: 18px;
  right: -35px;
  font-weight: bold;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
`;

const DineoutWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  color: #717171;
  padding: 0 12px;
`;

export const OfferWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  width: 100%;
`;

const useStyles = makeStyles({
  root: {
    maxWidth: "325px",
    margin: "4px 8px",
  },
  media: {
    width: "325px",
    height: "120.25px",
  },
  root2: {
    maxWidth: "350px",
    margin: "12px 12px",
  },
  media2: {
    width: "350px",
    height: "129.25px",
  },
  button: {
    textTransform: "none",
    color: "white",
    fontSize: "14px",
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
  },
  action: {
    "&:hover $focusHighlight": {
      opacity: 0,
    },
  },
  focusHighlight: {},
});

export default CouponListItem;
