import { CardContent, CardMedia, makeStyles } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import React from "react";
import Skeleton from "react-loading-skeleton";
import { useHistory } from "react-router-dom";

const CouponLoadingItem = () => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <Card className={!history.location.pathname.includes("/coupons") ? classes.root : classes.root2}>
      <CardMedia
        style={{ marginBottom: "22px" }}
        className={!history.location.pathname.includes("/coupons") ? classes.media : classes.media2}
      >
        <CardContent>
          <Skeleton
            className={!history.location.pathname.includes("/coupons") ? classes.media : classes.media2}
          ></Skeleton>
        </CardContent>
      </CardMedia>
      <CardContent
        style={{ height: "100px", display: "flex", flexDirection: "column", justifyContent: "space-between" }}
      >
        <Skeleton style={{ height: "20px", width: "100%", marginBottom: "15px" }} count={3} />
      </CardContent>
    </Card>
  );
};

const useStyles = makeStyles({
  root: {
    maxWidth: "325px",
    height: "270px",
    margin: "4px 8px",
    cursor: "pointer",
  },
  media: {
    width: "325px",
    height: "110px",
  },
  root2: {
    maxWidth: "350px",
    margin: "12px 12px",
    cursor: "pointer",
  },
  media2: {
    width: "350px",
    height: "129.25px",
  },
});

export default CouponLoadingItem;
