import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next/";
import AboutMainContent from "../components/About/AboutMainContent";
import PageTopText from "../components/PageTopText";

const AboutPage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>{t("HelmetAboutTitle")}</title>
        <meta name="description" content={t("HelmetAboutContent")} />
      </Helmet>

      <PageTopText t1={t("AboutPageTopText")} t2={t("AboutPageTopTextInfo")} />
      <div className="container">
        <AboutMainContent />
      </div>
    </>
  );
};

export default AboutPage;
