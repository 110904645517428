import React from "react";
import styled from "styled-components";

const AboutImage = (props: { link: string }) => {
  const { link } = props;

  const ImageStyled = styled.div`
    display: flex;
    background-image: url(${link});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    height: 300px;
    width: 322px;
    align-self: center;
    margin: 16px 0;
    border-radius: 5%;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    background-color: #a0a0a0;
  `;
  return <ImageStyled />;
};

export default AboutImage;
