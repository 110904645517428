import { Card, CardActionArea, makeStyles } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next/";
import { useHistory } from "react-router-dom";

const MoreCard = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <div>
      <Card className={classes.root} style={{ margin: "4px 8px" }}>
        <CardActionArea
          onClick={() => history.push("/coupons")}
          className={classes.root}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div style={{ fontSize: "40px" }}>🎫</div>
          <div style={{ fontSize: "18px" }}>{t("AppMore")}</div>
        </CardActionArea>
      </Card>
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    display: "flex",
    width: "325px",
    height: "270px",
    alignItems: "center",
    justifyContent: "center",
  },
});

export default MoreCard;
