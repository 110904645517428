const theme = {
  colors: {
    primary: "#008080",
    secondary: "#9764c7",
    white: "#ffffff",
    black: "#34404e",
  },
};

export default theme;
