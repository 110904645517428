import React, { createContext, ReactNode, useContext, useState } from "react";

interface IContext {
  isActive: boolean;
  setActive: React.Dispatch<React.SetStateAction<boolean>>;
}

const BurgerContext = createContext({} as IContext);

export const useBurger = () => {
  return useContext(BurgerContext);
};

const BurgerProvider = ({ children }: { children: ReactNode }) => {
  const [isActive, setActive] = useState(false);

  return <BurgerContext.Provider value={{ isActive, setActive }}>{children}</BurgerContext.Provider>;
};

export default BurgerProvider;
