import axios from "axios";

export const initCouponsQueryClientFetcher = () => {
  return <TData, TVariables>(query: string, variables?: TVariables) =>
    async (): Promise<TData> => {
      const res = await axios.post(
        "https://coupons-api.azurewebsites.net/graphql/",
        {
          query,
          variables,
        },
        {
          withCredentials: false,
          headers: {
            "Accept-Language": "is-IS",
          },
        },
      );

      if (res.status !== 200) {
        // const {message} = json.errors[0];
        // TODO ExtractError function
        throw new Error("Invalid GraphQL Response");
      }

      return res.data.data;
    };
};

export const fetcher = initCouponsQueryClientFetcher();
