import { Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next/";
import MediaQuery from "react-responsive";
import styled from "styled-components";
import { StoreButtons } from "../../styles/mobileStoreButtons";

const AppStore = styled.img.attrs({
  src: "https://couponswebapp.s3.eu-west-2.amazonaws.com/vefsida-peturaron/apple.png",
})`
  position: relative;
  width: 150px;
  @media screen and (min-width: 768px) {
    width: 200px;
  }
`;

// https://play.google.com/intl/en_us/badges/images/apps/en-play-badge.png
const GooglePlay = styled.img.attrs({
  src: "https://couponswebapp.s3.eu-west-2.amazonaws.com/vefsida-peturaron/google.png",
})`
  position: relative;
  width: 171px;

  @media screen and (min-width: 768px) {
    width: 224px;
  }
`;

const HomeInfo = () => {
  const { t } = useTranslation();

  return (
    <div style={{ display: "flex", position: "relative", flexDirection: "column", alignItems: "center" }}>
      <MediaQuery query="(min-width: 768px)">
        <Typography style={{ marginBottom: "4px" }} variant="h3">
          Icelandic Coupons
        </Typography>

        <Typography variant="h5" color="textSecondary">
          {t("AppHome")}
        </Typography>
      </MediaQuery>
      <MediaQuery query="(max-width: 767px)">
        <Typography variant="h4" style={{ marginBottom: "8px" }}>
          Icelandic Coupons
        </Typography>
        <Typography variant="h6" color="textSecondary">
          {t("AppHomeInfo")}
        </Typography>
        <Typography variant="h6" color="textSecondary">
          {t("AppHomeInfoSub")}
        </Typography>
      </MediaQuery>

      <StoreButtons>
        <a href="https://apps.apple.com/us/app/icelandic-coupons/id1317769121" style={{ marginRight: "20px" }}>
          <AppStore />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.loftfar.coupons">
          <GooglePlay />
        </a>
      </StoreButtons>
    </div>
  );
};

export default HomeInfo;

// const AppStoreImage = styled.div`
//   display: flex;
//   position: relative;
//   background-image: url(${appStoreIcon});
//   background-size: contain;
//   background-repeat: no-repeat;
//   background-position: center center;
//   width: 150px;
//   height: 51px;
//   margin-right: 20px;

//   @media screen and (min-width: 768px) {
//     width: 200px;
//     height: 66px;
//   }
// `;

// const GoogleImage = styled.div`
//   display: flex;
//   position: relative;
//   background-image: url(${googlePlayIcon});
//   background-size: contain;
//   background-repeat: no-repeat;
//   background-position: center center;
//   width: 171px;
//   height: 51px;

//   @media screen and (min-width: 768px) {
//     width: 224px;
//     height: 66px;
//   }
// `;
