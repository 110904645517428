import React, { useState } from "react";
import StripeCheckout from "react-stripe-checkout";
import axios from "axios";
import { Button, makeStyles, Typography } from "@material-ui/core";
import styled from "styled-components";

const StripePage = () => {
  const classes = useStyles();
  const [success, setSuccess] = useState(false);
  const [code, setCode] = useState("");

  const publishableKey = `${process.env.STRIPE_PUBLISHABLE_KEY}`;

  const onToken = (token: any) => {
    const body = {
      amount: 999,
      token: token,
    };

    axios
      .post(`${process.env.STRIPE_API_URL}`, body)
      .then((response) => {
        // console.log(response);
        setSuccess(true);
        setCode(response.data.message);
        // alert(response.data.message);
      })
      .catch((error) => {
        // console.log("Payment Error: ", error);
        alert("Payment Error");
      });
  };

  return (
    <ParentDiv>
      <ContentDiv>
        {!success ? (
          <>
            <Typography variant="h5" style={{ color: "white", fontWeight: "bold" }}>
              Access all coupons for only $9.99
            </Typography>
            <SizedBox />
            <Typography variant="h6" style={{ color: "white" }}>
              The coupons are a digital version of the Icelandic Coupons brochure!
            </Typography>
            <SizedBox />
            <Typography variant="h6" style={{ color: "white" }}>
              Upon purchase you will receive a code displayed on the screen to unlock all the coupons in the app.
            </Typography>
            <SizedBox />
            <Typography variant="h6" style={{ color: "white" }}>
              You will also receive an e-mail containing the code if you will need it in the future.
            </Typography>
            <SizedBox />
            <Typography style={{ color: "white", fontSize: "16px" }}>
              NOTE: After successful purchase the code will appear after 5-10sec delay on this page!
            </Typography>
            <SizedBox />
            <StripeCheckout
              label="Go Premium" //Component button text
              name="Buy Digital Coupons" //Modal Header
              // description="Upgrade to a premium account today."
              panelLabel="Pay" //Submit button in modal
              amount={999} //Amount in cents $9.99
              token={onToken}
              stripeKey={publishableKey}
              image="https://s3.eu-west-2.amazonaws.com/couponswebapp/logo-payment.jpg" //Pop-in header image
              billingAddress={false}
            >
              <Button color="primary" variant="contained" className={classes.root}>
                Pay with card
              </Button>
            </StripeCheckout>
          </>
        ) : (
          <>
            <Typography variant="h5" style={{ color: "white", fontWeight: "bold", margin: "15px auto" }}>
              Payment Successful!
            </Typography>
            <Typography variant="h6" style={{ color: "white", margin: "20px auto" }}>
              Simply open the app and go to the top left Navigation Drawer → Buy Coupons, then enter your code to unlock
              all the coupons in the app!
            </Typography>

            <Typography variant="h6" style={{ color: "white", margin: "20px auto 40px auto" }}>
              We have also sent you a confirmation e-mail with this code, enjoy!
            </Typography>

            <CodeDiv>
              <Typography variant="h6" style={{ color: "white", margin: "20px auto", fontWeight: "bold" }}>
                {code}
              </Typography>
            </CodeDiv>
          </>
        )}
      </ContentDiv>
    </ParentDiv>
  );
};

const useStyles = makeStyles({
  root: {
    textTransform: "none",
    color: "white",
    fontSize: "20px",
    padding: "10px 38px",
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    borderRadius: 50,
    border: "none",
    outline: "none",
  },
});

const ParentDiv = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 40px;
  height: 100vh;
  width: 100vw;
  background: linear-gradient(91deg, rgb(70, 130, 226) 0%, rgb(79, 64, 238));
  text-align: center;
`;

const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  justify-content: space-between;
  margin: 0 20px;
`;

const CodeDiv = styled.div`
  padding: 0 10px;
  border-radius: 5%;
  border: 2px solid white;
`;

const SizedBox = styled.div`
  height: 24px;
`;

export default StripePage;
