import { Button, makeStyles } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next/";
import MediaQuery from "react-responsive";
import { useHistory } from "react-router-dom";

const MoreInfo = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  return (
    <div>
      <MediaQuery query="(min-width: 768px)">
        <div style={{ fontSize: "40px" }}>{t("AppMoreInfo")}</div>
        <div style={{ margin: "30px 0px", maxWidth: "800px", fontSize: "19px" }}>{t("AppMoreInfoText")}</div>
        <Button
          onClick={() => history.push({ pathname: "/coupons", search: history.location.search })}
          variant="contained"
          color="primary"
          className={classes.root}
        >
          {t("AppMoreInfoButton")}
        </Button>
      </MediaQuery>
      <MediaQuery query="(max-width: 767px)">
        <div style={{ fontSize: "30px", margin: "auto 16px" }}>{t("AppMoreInfo")}</div>
        <div style={{ margin: "24px 16px", fontSize: "18px" }}>{t("AppMoreInfoText")}</div>
        <Button
          onClick={() => history.push({ pathname: "/coupons", search: history.location.search })}
          variant="contained"
          color="primary"
          className={classes.root}
          style={{ margin: "auto 16px" }}
        >
          {t("AppMoreInfoButton")}
        </Button>
      </MediaQuery>
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    textTransform: "none",
    color: "white",
    fontSize: "20px",
    padding: "10px 78px",
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    borderRadius: 50,
    border: "none",
    outline: "none",
  },
});

export default MoreInfo;
