import { Typography } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

const PageTopText = ({ t1, t2 }: { t1: string; t2: string }) => {
  return (
    <ParentDiv>
      <div style={{ display: "flex", flexDirection: "column", margin: "30px auto 0" }}>
        <Typography gutterBottom variant="h3" component="h2" style={{ fontWeight: "bold" }}>
          {t1}
          <Typography
            variant="h5"
            color="textSecondary"
            component="p"
            style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}
          >
            {t2}
          </Typography>
        </Typography>
      </div>
    </ParentDiv>
  );
};

const ParentDiv = styled.div`
  display: flex;
  max-width: 1400px;
  margin: 0 auto;
  padding-bottom: 12px;
  padding-top: 4px;
  flex-direction: column;
  align-items: center;
`;

export default PageTopText;
