import {
  AppBar,
  Drawer,
  List,
  ListItem,
  makeStyles,
} from "@material-ui/core";
import React, { Fragment, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import MediaQuery from "react-responsive";
import LanguageIcons from "./LanguageIcons";
import CouponsLogo from "./CouponsLogo";
import { useTranslation } from "react-i18next/";
import Burger from "./Burger";
import { useBurger } from "../../context/BurgerContext";

const Header = () => {
  const { isActive, setActive } = useBurger();

  const handleBurger = () => {
    setActive(!isActive);
  };

  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();

  const [openDrawer, setOpenDrawer] = useState(false);

  const toggle = () => {
    setOpenDrawer((prevState) => !prevState);
  };

  type Anchor = "top" | "left" | "bottom" | "right";

  // Main Menu navigation list items mobile
  const list = (anchor: Anchor) => (
    <div onClick={toggle}>
      <List>{menuNavigation}</List>
    </div>
  );

  const [open, setOpen] = React.useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [drop, setDrop] = React.useState(false);

  const handleDrop = () => {
    setDrop(!drop);
  };

  const menuNavigation = (
    <>
      <MediaQuery query="(min-width: 769px)">
        <ListItem
          selected={history.location.pathname === "/app"}
          onClick={() => history.push({ pathname: "/app", search: history.location.search })}
        >
          {t("HeaderAppText")}
        </ListItem>
        <ListItem
          selected={history.location.pathname === "/brochure"}
          onClick={() => history.push({ pathname: "/brochure", search: history.location.search })}
        >
          {t("HeaderBrochureText")}
        </ListItem>
        {/* <div>
          <ListItem
            style={{
              display: "flex",
              color: "white",
              textDecoration: "none",
              alignSelf: "center",
              marginRight: "-8px",
            }}
            ref={anchorRef}
            onClick={handleToggle}
          >
            {t("HeaderBrochureText")} {open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Popper open={open} anchorEl={anchorRef.current} transition disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow {...TransitionProps}>
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <List style={{ fontSize: "18px" }}>
                      <ListItem
                        style={{ display: "flex", justifyContent: "center" }}
                        selected={history.location.pathname === "/brochure"}
                        className={classes.root}
                        onClick={() => {
                          history.push({ pathname: "/brochure", search: history.location.search });
                          setOpen(false);
                        }}
                      >
                        {t("HeaderBrochureTextSub")}
                      </ListItem>
                      <ListItem
                        style={{ display: "flex", justifyContent: "center" }}
                        selected={history.location.pathname === "/brochure/access"}
                        className={classes.root}
                        onClick={() => {
                          history.push({ pathname: "/brochure/access", search: history.location.search });
                          setOpen(false);
                        }}
                      >
                        {t("HeaderBrochureTextSubSub")}
                      </ListItem>
                    </List>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div> */}
        <ListItem
          selected={history.location.pathname === "/coupons"}
          onClick={() => history.push({ pathname: "/coupons", search: history.location.search })}
        >
          {t("HeaderCouponsText")}
        </ListItem>
        <ListItem
          selected={history.location.pathname === "/about"}
          onClick={() => history.push({ pathname: "/about", search: history.location.search })}
        >
          <div style={{ whiteSpace: "nowrap" }}>{t("HeaderAboutText")}</div>
        </ListItem>
        <LanguageIcons size={24} />
      </MediaQuery>
    </>
  );

  const AppBarContent = (
    <HeaderWrapper>
      <CouponsLogo />

      <MediaQuery query="(min-width: 769px)">
        <List>
          <ListWrapper>{menuNavigation}</ListWrapper>
        </List>
      </MediaQuery>

      <MediaQuery query="(max-width: 768px)">
        <MenuWrapper>
          <LanguageIcons size={22} />
          {(["top"] as Anchor[]).map((anchor) => (
            <Fragment key={anchor}>
              <div onClick={toggle} style={{ marginLeft: "20px", marginRight: "0px" }}>
                <Burger />
              </div>
              <Drawer
                anchor={anchor}
                open={openDrawer}
                onClose={toggle}
                transitionDuration={300}
                onBackdropClick={handleBurger}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    fontSize: "24px",
                    paddingTop: "80px",
                  }}
                >
                  <div style={{ margin: "auto" }}>
                    <ListItem
                      classes={{ root: classes.listItem }}
                      button
                      selected={history.location.pathname === "/app"}
                      onClick={() => {
                        history.push({ pathname: "/app", search: history.location.search });
                        toggle();
                        handleBurger();
                      }}
                    >
                      {t("HeaderAppText")}
                    </ListItem>
                    <ListItem
                      classes={{ root: classes.listItem }}
                      button
                      selected={history.location.pathname === "/brochure"}
                      onClick={() => {
                        history.push({ pathname: "/brochure", search: history.location.search });
                        toggle();
                        handleBurger();
                      }}
                    >
                      {t("HeaderBrochureText")}
                    </ListItem>

                    {/* <ListItem button onClick={handleDrop} classes={{ root: classes.listItemBrochure }}>
                      {t("HeaderBrochureText")}
                      {drop ? <ExpandLess /> : <ExpandMore />}
                    </ListItem> */}

                    {/* <Collapse in={drop} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        <ListItem
                          classes={{ root: classes.listItem }}
                          button
                          onClick={() => {
                            history.push({ pathname: "/brochure", search: history.location.search });
                            toggle();
                            handleBurger();
                            handleDrop();
                          }}
                        >
                          <ListItemText classes={{ root: classes.listItem }} primary={t("HeaderBrochureTextSub")} />
                        </ListItem>
                        <ListItem
                          classes={{ root: classes.listItem }}
                          button
                          onClick={() => {
                            history.push({ pathname: "/brochure/access", search: history.location.search });
                            toggle();
                            handleBurger();
                            handleDrop();
                          }}
                        >
                          <ListItemText classes={{ root: classes.listItem }} primary={t("HeaderBrochureTextSubSub")} />
                        </ListItem>
                      </List>
                    </Collapse> */}
                    <ListItem
                      classes={{ root: classes.listItem }}
                      button
                      selected={history.location.pathname === "/coupons"}
                      onClick={() => {
                        history.push({ pathname: "/coupons", search: history.location.search });
                        toggle();
                        handleBurger();
                      }}
                    >
                      {t("HeaderCouponsText")}
                    </ListItem>
                    <ListItem
                      classes={{ root: classes.listItem }}
                      button
                      selected={history.location.pathname === "/about"}
                      onClick={() => {
                        history.push({ pathname: "/about", search: history.location.search });
                        toggle();
                        handleBurger();
                      }}
                    >
                      {t("HeaderAboutText")}
                    </ListItem>
                    {list(anchor)}
                  </div>
                </div>
              </Drawer>
            </Fragment>
          ))}
        </MenuWrapper>
      </MediaQuery>
    </HeaderWrapper>
  );

  return (
    <>
      {history.location.pathname.includes("/app") || history.location.pathname === "/brochure" ? (
        <AppBar style={{ backgroundColor: "transparent" }} position="absolute" elevation={0}>
          {AppBarContent}
        </AppBar>
      ) : (
        <AppBar
          style={{ background: "linear-gradient(91deg, rgb(70, 130, 226) 0%, rgb(79, 64, 238)" }}
          position="sticky"
          elevation={0}
        >
          {AppBarContent}
        </AppBar>
        // style={{ background: "linear-gradient(91deg, rgb(70, 130, 226) 0%, rgb(79, 64, 238)" }}
        // background: "linear-gradient(to right, rgb(45, 114, 224) 0%, rgb(79, 64, 238) 100%)"
        //darker
        // style={{ background: "linear-gradient(to right, rgb(45, 114, 224) 0%, rgb(79, 64, 238) 100%)" }}
      )}
    </>
  );
};

const useStyles = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&:focus": {
      backgroundColor: "transparent",
      color: "",
    },
  },
  list: {
    width: "auto",
  },
  listItem: {
    display: "flex",
    justifyContent: "center",
  },
  listItemBrochure: {
    display: "flex",
    justifyContent: "center",
    marginLeft: "4px",
  },
});

const keyIn = keyframes`
  from { transform: scale(1.0); -webkit-transform: scale(1.0); }
  to { transform: scale(1.1); -webkit-transform: scale(1.1); }
`;

const keyOut = keyframes`
  from { transform: scale(1.1); -webkit-transform: scale(1.1); }
  to { transform: scale(1); -webkit-transform: scale(1); }
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 24px;
  /* background-color: transparent; */

  /* background-color: #3c6ced; */
  /* background-color: #1c1c1c; */
  /* background-color: #181818; */
  /* background-color: #2a2a2a; */
  box-shadow: none;

  @media screen and (min-width: 768px) {
    padding: 14px 24px;
  }
`;

const ListWrapper = styled.ul`
  display: flex;
  flex-direction: row;

  font-size: 20px;
  align-self: "center";

  li:not(:hover) {
    animation: ${keyOut} 0.25s;
    /* animation-duration: 8s; */
  }

  li:hover {
    animation: ${keyIn} 0.25s;
    animation-fill-mode: forwards;
    /* text-decoration: underline;
    text-decoration-color: white;
    text-decoration-thickness: 3px; */
  }

  li:active {
    /* color: #dbdbdb; */
  }
`;

const MenuWrapper = styled.div`
  display: flex;
  padding-top: 9px;
  padding-bottom: 9px;
`;

export default Header;
