import { useMutation, useQuery, UseMutationOptions, UseQueryOptions } from '@tanstack/react-query';
import { fetcher } from '../clients/initCouponsQueryClientFetcher';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** The `Date` scalar represents an ISO-8601 compliant date type. */
  Date: { input: any; output: any; }
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: { input: any; output: any; }
  /** The `TimeSpan` scalar represents an ISO-8601 compliant duration type. */
  TimeSpan: { input: any; output: any; }
};

export enum AppCategoryType {
  Food = 'FOOD',
  Play = 'PLAY',
  Shopping = 'SHOPPING'
}

export type ApplePayEncryptedPaymentDataInput = {
  data?: InputMaybe<Scalars['String']['input']>;
  header?: InputMaybe<ApplePayHeaderInput>;
  signature?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

export type ApplePayEncryptedTokenInput = {
  paymentData?: InputMaybe<ApplePayEncryptedPaymentDataInput>;
  paymentMethod?: InputMaybe<ApplePayPaymentMethodInput>;
  transactionIdentifier?: InputMaybe<Scalars['String']['input']>;
};

export type ApplePayHeaderInput = {
  ephemeralPublicKey?: InputMaybe<Scalars['String']['input']>;
  publicKeyHash?: InputMaybe<Scalars['String']['input']>;
  transactionId?: InputMaybe<Scalars['String']['input']>;
};

export type ApplePayPaymentMethodInput = {
  displayName?: InputMaybe<Scalars['String']['input']>;
  network?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type AreaViewModel = {
  __typename?: 'AreaViewModel';
  country: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type AvailabilityModel = {
  __typename?: 'AvailabilityModel';
  companyId: Scalars['Int']['output'];
  excludeDates: Array<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  isTimeContinuous: Scalars['Boolean']['output'];
  priority: Scalars['Int']['output'];
  recurrenceExcludeDates?: Maybe<Scalars['String']['output']>;
  recurrenceRule: Scalars['String']['output'];
  ruleExtensionType: RuleExtensionType;
  startDate: Scalars['Date']['output'];
  times: Array<AvailabilityTimesModel>;
};

export type AvailabilityModelInput = {
  companyId: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
  isTimeContinuous: Scalars['Boolean']['input'];
  priority: Scalars['Int']['input'];
  recurrenceExcludeDates?: InputMaybe<Scalars['String']['input']>;
  recurrenceRule: Scalars['String']['input'];
  ruleExtensionType: RuleExtensionType;
  startDate: Scalars['Date']['input'];
  times: Array<AvailabilityTimesModelInput>;
};

export type AvailabilityTimesModel = {
  __typename?: 'AvailabilityTimesModel';
  availabilityId: Scalars['Int']['output'];
  fromTime: Scalars['TimeSpan']['output'];
  id: Scalars['Int']['output'];
  toTime: Scalars['TimeSpan']['output'];
};

export type AvailabilityTimesModelInput = {
  availabilityId: Scalars['Int']['input'];
  fromTime: Scalars['TimeSpan']['input'];
  id: Scalars['Int']['input'];
  toTime: Scalars['TimeSpan']['input'];
};

export type CardViewModelInput = {
  cvc: Scalars['Int']['input'];
  expiryMonth: Scalars['String']['input'];
  expiryYear: Scalars['String']['input'];
  number: Scalars['String']['input'];
};

export type CityModel = {
  __typename?: 'CityModel';
  areaId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  zipCode: Scalars['Int']['output'];
};

export type CompanyCategoryViewModel = {
  __typename?: 'CompanyCategoryViewModel';
  companyCategoryId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type CompanyLanguageModel = {
  __typename?: 'CompanyLanguageModel';
  companyId: Scalars['Int']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  language: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type CompanyLanguageMutationViewModelInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  language: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CompanyLocationModel = {
  __typename?: 'CompanyLocationModel';
  address: Scalars['String']['output'];
  cityId: Scalars['Int']['output'];
  companyId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  phone?: Maybe<Scalars['String']['output']>;
};

export type CompanyLocationModelInput = {
  address: Scalars['String']['input'];
  cityId: Scalars['Int']['input'];
  companyId: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
  isDeleted: Scalars['Boolean']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyLocationViewModel = {
  __typename?: 'CompanyLocationViewModel';
  address: Scalars['String']['output'];
  areaId: Scalars['Int']['output'];
  cityId: Scalars['Int']['output'];
  cityName: Scalars['String']['output'];
  companyId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  zipCode: Scalars['Int']['output'];
};

export type CompanyMutationViewModelInput = {
  bookingUrl?: InputMaybe<Scalars['String']['input']>;
  companyCategoryIds: Array<Scalars['Int']['input']>;
  countryCode: Scalars['String']['input'];
  dineoutId?: InputMaybe<Scalars['Int']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  languages: Array<CompanyLanguageMutationViewModelInput>;
  locations: Array<CompanyLocationModelInput>;
  phone?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyViewModel = {
  __typename?: 'CompanyViewModel';
  bookingUrl?: Maybe<Scalars['String']['output']>;
  categories: Array<CompanyCategoryViewModel>;
  countryCode: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  descriptionWithoutHtml?: Maybe<Scalars['String']['output']>;
  dineoutId?: Maybe<Scalars['Int']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  languages: Array<CompanyLanguageModel>;
  locations: Array<CompanyLocationModel>;
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  updated: Scalars['DateTime']['output'];
  website?: Maybe<Scalars['String']['output']>;
};

export enum CompleteResponse {
  Error = 'ERROR',
  Redirect = 'REDIRECT',
  Success = 'SUCCESS'
}

export type CouponCategoryViewModel = {
  __typename?: 'CouponCategoryViewModel';
  appCategoryId: Scalars['Int']['output'];
  couponCategoryId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type CouponLanguageModel = {
  __typename?: 'CouponLanguageModel';
  couponId: Scalars['Int']['output'];
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  language: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type CouponLanguageMutationViewModelInput = {
  description: Scalars['String']['input'];
  language: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type CouponMutationViewModelInput = {
  applyAllLocations: Scalars['Boolean']['input'];
  availabilities: Array<AvailabilityModelInput>;
  companyId: Scalars['Int']['input'];
  couponCategoryIds: Array<Scalars['Int']['input']>;
  discount: DiscountModelInput;
  expires: Scalars['Date']['input'];
  id: Scalars['Int']['input'];
  isFree: Scalars['Boolean']['input'];
  isVisibleOnCoupons: Scalars['Boolean']['input'];
  isVisibleOnCouponsTourist: Scalars['Boolean']['input'];
  isVisibleOnDineout: Scalars['Boolean']['input'];
  languages: Array<CouponLanguageMutationViewModelInput>;
  locationIds: Array<Scalars['Int']['input']>;
  order: Scalars['String']['input'];
  photo: PhotoMutationViewModelInput;
  redeemCount?: InputMaybe<Scalars['Int']['input']>;
  squarePhoto: PhotoMutationViewModelInput;
};

export type CouponQueryByIdRequestViewModelInput = {
  couponId: Scalars['Int']['input'];
  firebaseUId?: InputMaybe<Scalars['String']['input']>;
};

export type CouponQueryByIdsInput = {
  couponIds: Array<Scalars['Int']['input']>;
  source?: InputMaybe<CouponsSource>;
};

export type CouponQueryRequestViewModelInput = {
  appCategoryId?: InputMaybe<Scalars['Int']['input']>;
  areaId?: InputMaybe<Scalars['Int']['input']>;
  couponCategoryId?: InputMaybe<Scalars['Int']['input']>;
  firebaseUId?: InputMaybe<Scalars['String']['input']>;
  sortRandomly?: InputMaybe<Scalars['Boolean']['input']>;
  source?: InputMaybe<CouponsSource>;
  whenFilter: CouponsWhenFilter;
};

export type CouponRedeemMutationViewModelInput = {
  couponId: Scalars['Int']['input'];
  firebaseUId: Scalars['String']['input'];
  operatingSystem?: InputMaybe<OperatingSystemMetaInput>;
};

export type CouponViewModel = {
  __typename?: 'CouponViewModel';
  applyAllLocations: Scalars['Boolean']['output'];
  availabilities: Array<AvailabilityModel>;
  canBeRedeemed: Scalars['Boolean']['output'];
  categories: Array<CouponCategoryViewModel>;
  companyId: Scalars['Int']['output'];
  companyName: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  descriptionWithoutHtml: Scalars['String']['output'];
  discount: DiscountModel;
  expires: Scalars['Date']['output'];
  id: Scalars['Int']['output'];
  isAvailableNow: Scalars['Boolean']['output'];
  isDeleted: Scalars['Boolean']['output'];
  isFree: Scalars['Boolean']['output'];
  isVisibleOnCoupons: Scalars['Boolean']['output'];
  isVisibleOnCouponsTourist: Scalars['Boolean']['output'];
  isVisibleOnDineout: Scalars['Boolean']['output'];
  languages: Array<CouponLanguageModel>;
  locations: Array<CompanyLocationViewModel>;
  order: Scalars['String']['output'];
  photo?: Maybe<PhotoViewModel>;
  redeemCount?: Maybe<Scalars['Int']['output']>;
  redeemsLeft?: Maybe<Scalars['Int']['output']>;
  squarePhoto?: Maybe<PhotoViewModel>;
  title: Scalars['String']['output'];
  updated: Scalars['DateTime']['output'];
};

export type CouponWeekAvailabilityViewModel = {
  __typename?: 'CouponWeekAvailabilityViewModel';
  couponId: Scalars['Int']['output'];
  days: Array<WeekdayAvailabilityViewModel>;
};

export enum CouponsSource {
  Coupons = 'COUPONS',
  CouponsTourist = 'COUPONS_TOURIST',
  Dineout = 'DINEOUT'
}

export enum CouponsWhenFilter {
  All = 'ALL',
  Dinner = 'DINNER',
  Lunch = 'LUNCH',
  Now = 'NOW',
  Today = 'TODAY'
}

export enum DayOfWeek {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY'
}

export type DiscountModel = {
  __typename?: 'DiscountModel';
  flatFee?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  percentage?: Maybe<Scalars['Int']['output']>;
  type: DiscountType;
};

export type DiscountModelInput = {
  flatFee?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
  percentage?: InputMaybe<Scalars['Int']['input']>;
  type: DiscountType;
};

export enum DiscountType {
  FlatFee = 'FLAT_FEE',
  Percentage = 'PERCENTAGE',
  TwoForOne = 'TWO_FOR_ONE'
}

export type FrequentlyAskedQuestionLanguage = {
  __typename?: 'FrequentlyAskedQuestionLanguage';
  answer: Scalars['String']['output'];
  faqId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  language: Scalars['String']['output'];
  question: Scalars['String']['output'];
};

export type FromToTimeViewModel = {
  __typename?: 'FromToTimeViewModel';
  fromTime: Scalars['TimeSpan']['output'];
  toTime: Scalars['TimeSpan']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createCompany?: Maybe<Scalars['Int']['output']>;
  createCoupon?: Maybe<Scalars['Int']['output']>;
  createPromoCode: Scalars['Int']['output'];
  createSubscription: SubscriptionResponseViewModel;
  deleteCoupon: Scalars['Int']['output'];
  deletePromoCode: Scalars['Int']['output'];
  redeemCoupon: Scalars['Boolean']['output'];
  updateCompany?: Maybe<Scalars['Int']['output']>;
  updateCoupon: Scalars['Int']['output'];
  updateCouponOrder: Scalars['Int']['output'];
  updatePromoCode: Scalars['Int']['output'];
};


export type MutationCreateCompanyArgs = {
  company: CompanyMutationViewModelInput;
};


export type MutationCreateCouponArgs = {
  coupon: CouponMutationViewModelInput;
};


export type MutationCreatePromoCodeArgs = {
  promoCode: PromoCodeMutationViewModelInput;
};


export type MutationCreateSubscriptionArgs = {
  subscriptionMutation: SubscriptionMutationViewModelInput;
};


export type MutationDeleteCouponArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeletePromoCodeArgs = {
  id: Scalars['Int']['input'];
};


export type MutationRedeemCouponArgs = {
  data: CouponRedeemMutationViewModelInput;
};


export type MutationUpdateCompanyArgs = {
  company: CompanyMutationViewModelInput;
};


export type MutationUpdateCouponArgs = {
  coupon: CouponMutationViewModelInput;
};


export type MutationUpdateCouponOrderArgs = {
  couponId: Scalars['Int']['input'];
  order: Scalars['String']['input'];
};


export type MutationUpdatePromoCodeArgs = {
  promoCode: PromoCodeMutationViewModelInput;
};

export type OperatingSystemMetaInput = {
  operatingSystem: Scalars['String']['input'];
  operatingSystemVersion: Scalars['String']['input'];
  source: Scalars['String']['input'];
};

export type PhotoLanguageMutationModelInput = {
  base64Image?: InputMaybe<Scalars['String']['input']>;
  language: Scalars['String']['input'];
  shouldUpload: Scalars['Boolean']['input'];
};

export type PhotoMutationViewModelInput = {
  id: Scalars['Int']['input'];
  languages: Array<PhotoLanguageMutationModelInput>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type PhotoViewModel = {
  __typename?: 'PhotoViewModel';
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  publicId: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type PromoCodeMutationViewModelInput = {
  code: Scalars['String']['input'];
  discount: DiscountModelInput;
  id: Scalars['Int']['input'];
};

export type PromoCodeViewModel = {
  __typename?: 'PromoCodeViewModel';
  code: Scalars['String']['output'];
  discount: DiscountModel;
  id: Scalars['Int']['output'];
};

export type Query = {
  __typename?: 'Query';
  areas: Array<AreaViewModel>;
  cities: Array<CityModel>;
  companies: Array<CompanyViewModel>;
  company?: Maybe<CompanyViewModel>;
  coupon?: Maybe<CouponViewModel>;
  couponCategories: Array<CouponCategoryViewModel>;
  couponWeekAvailabilities: Array<CouponWeekAvailabilityViewModel>;
  coupons: Array<CouponViewModel>;
  couponsById: Array<CouponViewModel>;
  faq: Array<FrequentlyAskedQuestionLanguage>;
  promoCode?: Maybe<PromoCodeViewModel>;
  promoCodes: Array<PromoCodeViewModel>;
  subscriptionPackages: Array<SubscriptionPackageViewModel>;
};


export type QueryCompanyArgs = {
  id: Scalars['Int']['input'];
};


export type QueryCouponArgs = {
  query: CouponQueryByIdRequestViewModelInput;
};


export type QueryCouponCategoriesArgs = {
  type?: InputMaybe<AppCategoryType>;
};


export type QueryCouponWeekAvailabilitiesArgs = {
  couponIds: Array<Scalars['Int']['input']>;
};


export type QueryCouponsArgs = {
  query: CouponQueryRequestViewModelInput;
};


export type QueryCouponsByIdArgs = {
  query: CouponQueryByIdsInput;
};


export type QueryFaqArgs = {
  language: Scalars['String']['input'];
};


export type QueryPromoCodeArgs = {
  id: Scalars['Int']['input'];
};


export type QuerySubscriptionPackagesArgs = {
  query: SubscriptionQueryViewModelInput;
};

export type Redirect3dsDataInput = {
  cAVV?: InputMaybe<Scalars['String']['input']>;
  mDStatus?: InputMaybe<Scalars['String']['input']>;
  mpiToken?: InputMaybe<Scalars['String']['input']>;
  xID?: InputMaybe<Scalars['String']['input']>;
};

export enum RuleExtensionType {
  Custom = 'CUSTOM',
  DoesNotRepeat = 'DOES_NOT_REPEAT',
  None = 'NONE'
}

export type SubscriptionMutationViewModelInput = {
  allowCouponsEmails: Scalars['Boolean']['input'];
  applePayToken?: InputMaybe<ApplePayEncryptedTokenInput>;
  card?: InputMaybe<CardViewModelInput>;
  currency: Scalars['String']['input'];
  firebaseUId: Scalars['String']['input'];
  paymentType: SubscriptionPaymentType;
  promoCode?: InputMaybe<Scalars['String']['input']>;
  redirect3dsData?: InputMaybe<Redirect3dsDataInput>;
  subscriptionGuid?: InputMaybe<Scalars['String']['input']>;
  subscriptionPackageId: Scalars['Int']['input'];
};

export type SubscriptionPackagePriceModel = {
  __typename?: 'SubscriptionPackagePriceModel';
  currency: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  price: Scalars['Int']['output'];
};

export type SubscriptionPackageViewModel = {
  __typename?: 'SubscriptionPackageViewModel';
  discount?: Maybe<DiscountModel>;
  expiryDate: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  longDescription: Scalars['String']['output'];
  name: Scalars['String']['output'];
  prices: Array<SubscriptionPackagePriceModel>;
  shortDescription: Scalars['String']['output'];
};

export enum SubscriptionPaymentType {
  ApplePay = 'APPLE_PAY',
  Card = 'CARD'
}

export type SubscriptionQueryViewModelInput = {
  promoCode?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionResponseViewModel = {
  __typename?: 'SubscriptionResponseViewModel';
  redirectUrl?: Maybe<Scalars['String']['output']>;
  response: CompleteResponse;
  subscriptionGuid?: Maybe<Scalars['String']['output']>;
};

export type WeekdayAvailabilityViewModel = {
  __typename?: 'WeekdayAvailabilityViewModel';
  dayOfWeek: DayOfWeek;
  isAvailableOnWeekday: Scalars['Boolean']['output'];
  isTimeContinuous: Scalars['Boolean']['output'];
  isToday: Scalars['Boolean']['output'];
  times: Array<FromToTimeViewModel>;
};

export type CouponFragmentFragment = { __typename?: 'CouponViewModel', id: number, title: string, companyId: number, companyName: string, descriptionWithoutHtml: string, redeemCount?: number | null, canBeRedeemed: boolean, redeemsLeft?: number | null, photo?: { __typename?: 'PhotoViewModel', url: string, publicId: string } | null, locations: Array<{ __typename?: 'CompanyLocationViewModel', zipCode: number, cityName: string, address: string }>, discount: { __typename?: 'DiscountModel', type: DiscountType, percentage?: number | null, flatFee?: number | null }, languages: Array<{ __typename?: 'CouponLanguageModel', couponId: number, description: string, id: number, language: string, title: string }> };

export type CreateSubscriptionMutationVariables = Exact<{
  request: SubscriptionMutationViewModelInput;
}>;


export type CreateSubscriptionMutation = { __typename?: 'Mutation', createSubscription: { __typename?: 'SubscriptionResponseViewModel', subscriptionGuid?: string | null, response: CompleteResponse, redirectUrl?: string | null } };

export type RedeemCouponMutationVariables = Exact<{
  query: CouponRedeemMutationViewModelInput;
}>;


export type RedeemCouponMutation = { __typename?: 'Mutation', redeemCoupon: boolean };

export type AreasQueryVariables = Exact<{ [key: string]: never; }>;


export type AreasQuery = { __typename?: 'Query', areas: Array<{ __typename?: 'AreaViewModel', id: number, name: string }> };

export type CompaniesQueryVariables = Exact<{ [key: string]: never; }>;


export type CompaniesQuery = { __typename?: 'Query', companies: Array<{ __typename?: 'CompanyViewModel', id: number, dineoutId?: number | null, email?: string | null, bookingUrl?: string | null, website?: string | null, languages: Array<{ __typename?: 'CompanyLanguageModel', companyId: number, description?: string | null, id: number, language: string, name: string }> }> };

export type CompanyQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type CompanyQuery = { __typename?: 'Query', company?: { __typename?: 'CompanyViewModel', name: string, descriptionWithoutHtml?: string | null, website?: string | null } | null };

export type CouponAvailabilitiesQueryVariables = Exact<{
  couponIds: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;


export type CouponAvailabilitiesQuery = { __typename?: 'Query', couponWeekAvailabilities: Array<{ __typename?: 'CouponWeekAvailabilityViewModel', couponId: number, days: Array<{ __typename?: 'WeekdayAvailabilityViewModel', isAvailableOnWeekday: boolean, isToday: boolean, dayOfWeek: DayOfWeek, isTimeContinuous: boolean, times: Array<{ __typename?: 'FromToTimeViewModel', fromTime: any, toTime: any }> }> }> };

export type CouponCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type CouponCategoriesQuery = { __typename?: 'Query', couponCategories: Array<{ __typename?: 'CouponCategoryViewModel', couponCategoryId: number, name: string }> };

export type CouponQueryVariables = Exact<{
  query: CouponQueryByIdRequestViewModelInput;
}>;


export type CouponQuery = { __typename?: 'Query', coupon?: { __typename?: 'CouponViewModel', id: number, title: string, companyId: number, companyName: string, descriptionWithoutHtml: string, redeemCount?: number | null, canBeRedeemed: boolean, redeemsLeft?: number | null, photo?: { __typename?: 'PhotoViewModel', url: string, publicId: string } | null, locations: Array<{ __typename?: 'CompanyLocationViewModel', zipCode: number, cityName: string, address: string }>, discount: { __typename?: 'DiscountModel', type: DiscountType, percentage?: number | null, flatFee?: number | null }, languages: Array<{ __typename?: 'CouponLanguageModel', couponId: number, description: string, id: number, language: string, title: string }> } | null };

export type CouponsQueryVariables = Exact<{
  query: CouponQueryRequestViewModelInput;
}>;


export type CouponsQuery = { __typename?: 'Query', coupons: Array<{ __typename?: 'CouponViewModel', id: number, title: string, companyId: number, companyName: string, descriptionWithoutHtml: string, redeemCount?: number | null, canBeRedeemed: boolean, redeemsLeft?: number | null, photo?: { __typename?: 'PhotoViewModel', url: string, publicId: string } | null, locations: Array<{ __typename?: 'CompanyLocationViewModel', zipCode: number, cityName: string, address: string }>, discount: { __typename?: 'DiscountModel', type: DiscountType, percentage?: number | null, flatFee?: number | null }, languages: Array<{ __typename?: 'CouponLanguageModel', couponId: number, description: string, id: number, language: string, title: string }> }> };

export type SubscriptionPackagesQueryVariables = Exact<{
  query: SubscriptionQueryViewModelInput;
}>;


export type SubscriptionPackagesQuery = { __typename?: 'Query', subscriptionPackages: Array<{ __typename?: 'SubscriptionPackageViewModel', id: number, name: string, shortDescription: string, longDescription: string, expiryDate: any, prices: Array<{ __typename?: 'SubscriptionPackagePriceModel', id: number, currency: string, price: number }>, discount?: { __typename?: 'DiscountModel', type: DiscountType, percentage?: number | null, flatFee?: number | null } | null }> };


export const CouponFragmentFragmentDoc = `
    fragment CouponFragment on CouponViewModel {
  id
  title
  companyId
  companyName
  descriptionWithoutHtml
  redeemCount
  canBeRedeemed
  redeemsLeft
  photo {
    url
    publicId
  }
  locations {
    zipCode
    cityName
    address
  }
  discount {
    type
    percentage
    flatFee
  }
  languages {
    couponId
    description
    id
    language
    title
  }
}
    `;
export const CreateSubscriptionDocument = `
    mutation CreateSubscription($request: SubscriptionMutationViewModelInput!) {
  createSubscription(subscriptionMutation: $request) {
    subscriptionGuid
    response
    redirectUrl
  }
}
    `;

export const useCreateSubscriptionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateSubscriptionMutation, TError, CreateSubscriptionMutationVariables, TContext>) => {
    
    return useMutation<CreateSubscriptionMutation, TError, CreateSubscriptionMutationVariables, TContext>(
      ['CreateSubscription'],
      (variables?: CreateSubscriptionMutationVariables) => fetcher<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>(CreateSubscriptionDocument, variables)(),
      options
    )};

export const RedeemCouponDocument = `
    mutation RedeemCoupon($query: CouponRedeemMutationViewModelInput!) {
  redeemCoupon(data: $query)
}
    `;

export const useRedeemCouponMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<RedeemCouponMutation, TError, RedeemCouponMutationVariables, TContext>) => {
    
    return useMutation<RedeemCouponMutation, TError, RedeemCouponMutationVariables, TContext>(
      ['RedeemCoupon'],
      (variables?: RedeemCouponMutationVariables) => fetcher<RedeemCouponMutation, RedeemCouponMutationVariables>(RedeemCouponDocument, variables)(),
      options
    )};

export const AreasDocument = `
    query Areas {
  areas {
    id
    name
  }
}
    `;

export const useAreasQuery = <
      TData = AreasQuery,
      TError = unknown
    >(
      variables?: AreasQueryVariables,
      options?: UseQueryOptions<AreasQuery, TError, TData>
    ) => {
    
    return useQuery<AreasQuery, TError, TData>(
      variables === undefined ? ['Areas'] : ['Areas', variables],
      fetcher<AreasQuery, AreasQueryVariables>(AreasDocument, variables),
      options
    )};

export const CompaniesDocument = `
    query Companies {
  companies {
    id
    dineoutId
    email
    bookingUrl
    languages {
      companyId
      description
      id
      language
      name
    }
    website
  }
}
    `;

export const useCompaniesQuery = <
      TData = CompaniesQuery,
      TError = unknown
    >(
      variables?: CompaniesQueryVariables,
      options?: UseQueryOptions<CompaniesQuery, TError, TData>
    ) => {
    
    return useQuery<CompaniesQuery, TError, TData>(
      variables === undefined ? ['Companies'] : ['Companies', variables],
      fetcher<CompaniesQuery, CompaniesQueryVariables>(CompaniesDocument, variables),
      options
    )};

export const CompanyDocument = `
    query Company($id: Int!) {
  company(id: $id) {
    name
    descriptionWithoutHtml
    website
  }
}
    `;

export const useCompanyQuery = <
      TData = CompanyQuery,
      TError = unknown
    >(
      variables: CompanyQueryVariables,
      options?: UseQueryOptions<CompanyQuery, TError, TData>
    ) => {
    
    return useQuery<CompanyQuery, TError, TData>(
      ['Company', variables],
      fetcher<CompanyQuery, CompanyQueryVariables>(CompanyDocument, variables),
      options
    )};

export const CouponAvailabilitiesDocument = `
    query CouponAvailabilities($couponIds: [Int!]!) {
  couponWeekAvailabilities(couponIds: $couponIds) {
    couponId
    days {
      isAvailableOnWeekday
      isToday
      dayOfWeek
      isTimeContinuous
      times {
        fromTime
        toTime
      }
    }
  }
}
    `;

export const useCouponAvailabilitiesQuery = <
      TData = CouponAvailabilitiesQuery,
      TError = unknown
    >(
      variables: CouponAvailabilitiesQueryVariables,
      options?: UseQueryOptions<CouponAvailabilitiesQuery, TError, TData>
    ) => {
    
    return useQuery<CouponAvailabilitiesQuery, TError, TData>(
      ['CouponAvailabilities', variables],
      fetcher<CouponAvailabilitiesQuery, CouponAvailabilitiesQueryVariables>(CouponAvailabilitiesDocument, variables),
      options
    )};

export const CouponCategoriesDocument = `
    query CouponCategories {
  couponCategories {
    couponCategoryId
    name
  }
}
    `;

export const useCouponCategoriesQuery = <
      TData = CouponCategoriesQuery,
      TError = unknown
    >(
      variables?: CouponCategoriesQueryVariables,
      options?: UseQueryOptions<CouponCategoriesQuery, TError, TData>
    ) => {
    
    return useQuery<CouponCategoriesQuery, TError, TData>(
      variables === undefined ? ['CouponCategories'] : ['CouponCategories', variables],
      fetcher<CouponCategoriesQuery, CouponCategoriesQueryVariables>(CouponCategoriesDocument, variables),
      options
    )};

export const CouponDocument = `
    query Coupon($query: CouponQueryByIdRequestViewModelInput!) {
  coupon(query: $query) {
    ...CouponFragment
  }
}
    ${CouponFragmentFragmentDoc}`;

export const useCouponQuery = <
      TData = CouponQuery,
      TError = unknown
    >(
      variables: CouponQueryVariables,
      options?: UseQueryOptions<CouponQuery, TError, TData>
    ) => {
    
    return useQuery<CouponQuery, TError, TData>(
      ['Coupon', variables],
      fetcher<CouponQuery, CouponQueryVariables>(CouponDocument, variables),
      options
    )};

export const CouponsDocument = `
    query Coupons($query: CouponQueryRequestViewModelInput!) {
  coupons(query: $query) {
    ...CouponFragment
  }
}
    ${CouponFragmentFragmentDoc}`;

export const useCouponsQuery = <
      TData = CouponsQuery,
      TError = unknown
    >(
      variables: CouponsQueryVariables,
      options?: UseQueryOptions<CouponsQuery, TError, TData>
    ) => {
    
    return useQuery<CouponsQuery, TError, TData>(
      ['Coupons', variables],
      fetcher<CouponsQuery, CouponsQueryVariables>(CouponsDocument, variables),
      options
    )};

export const SubscriptionPackagesDocument = `
    query SubscriptionPackages($query: SubscriptionQueryViewModelInput!) {
  subscriptionPackages(query: $query) {
    id
    name
    shortDescription
    longDescription
    prices {
      id
      currency
      price
    }
    discount {
      type
      percentage
      flatFee
    }
    expiryDate
  }
}
    `;

export const useSubscriptionPackagesQuery = <
      TData = SubscriptionPackagesQuery,
      TError = unknown
    >(
      variables: SubscriptionPackagesQueryVariables,
      options?: UseQueryOptions<SubscriptionPackagesQuery, TError, TData>
    ) => {
    
    return useQuery<SubscriptionPackagesQuery, TError, TData>(
      ['SubscriptionPackages', variables],
      fetcher<SubscriptionPackagesQuery, SubscriptionPackagesQueryVariables>(SubscriptionPackagesDocument, variables),
      options
    )};
