import React from "react";
import { useTranslation } from "react-i18next/";
import { useHistory } from "react-router-dom";

const LanguageIcons = (props: { size: number }) => {
  const { i18n } = useTranslation();
  const changeLanguage = (lng: any) => {
    i18n.changeLanguage(lng);
  };

  const history = useHistory();
  const { size } = props;

  return (
    <>
      <div
        onClick={() => {
          changeLanguage("is");
          history.push({ search: "?lng=is" });
        }}
        style={{ display: "flex", alignItems: "center", margin: "auto 10px", cursor: "pointer" }}
      >
        <svg viewBox="0 0 512 512" height={size} width={size}>
          <circle cx="256" cy="256" r="256" fill="#f0f0f0"></circle>
          <g fill="#0052b4">
            <path d="M8.819 322.783c14.812 54.959 47.457 102.593 91.355 136.322V322.783H8.819zM233.739 511.026A259.29 259.29 0 00256 512c118.279 0 217.805-80.221 247.181-189.217H233.739v188.243zM503.181 189.217C473.805 80.221 374.279 0 256 0c-7.502 0-14.923.342-22.261.974v188.243h269.442zM100.174 52.895c-43.898 33.73-76.543 81.363-91.355 136.322h91.355V52.895z"></path>
          </g>
          <path
            d="M509.833 222.609H200.348V6.085a254.258 254.258 0 00-66.783 25.042v191.481H2.167C.742 233.539 0 244.683 0 256s.742 22.461 2.167 33.391h131.398v191.481a254.258 254.258 0 0066.783 25.042V289.392h309.485C511.256 278.461 512 267.317 512 256s-.744-22.461-2.167-33.391z"
            fill="#d80027"
          ></path>
        </svg>
      </div>
      <div
        onClick={() => {
          changeLanguage("en");
          history.push({ search: "?lng=en" });
        }}
        style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
      >
        <svg viewBox="0 0 512 512" width={size} height={size}>
          <circle cx="256" cy="256" r="256" fill="#f0f0f0"></circle>
          <g fill="#0052b4">
            <path d="M52.92 100.142c-20.109 26.163-35.272 56.318-44.101 89.077h133.178L52.92 100.142zM503.181 189.219c-8.829-32.758-23.993-62.913-44.101-89.076l-89.075 89.076h133.176zM8.819 322.784c8.83 32.758 23.993 62.913 44.101 89.075l89.074-89.075H8.819zM411.858 52.921c-26.163-20.109-56.317-35.272-89.076-44.102v133.177l89.076-89.075zM100.142 459.079c26.163 20.109 56.318 35.272 89.076 44.102V370.005l-89.076 89.074zM189.217 8.819c-32.758 8.83-62.913 23.993-89.075 44.101l89.075 89.075V8.819zM322.783 503.181c32.758-8.83 62.913-23.993 89.075-44.101l-89.075-89.075v133.176zM370.005 322.784l89.075 89.076c20.108-26.162 35.272-56.318 44.101-89.076H370.005z"></path>
          </g>
          <g fill="#d80027">
            <path d="M509.833 222.609H289.392V2.167A258.556 258.556 0 00256 0c-11.319 0-22.461.744-33.391 2.167v220.441H2.167A258.556 258.556 0 000 256c0 11.319.744 22.461 2.167 33.391h220.441v220.442a258.35 258.35 0 0066.783 0V289.392h220.442A258.533 258.533 0 00512 256c0-11.317-.744-22.461-2.167-33.391z"></path>
            <path d="M322.783 322.784L437.019 437.02a256.636 256.636 0 0015.048-16.435l-97.802-97.802h-31.482v.001zM189.217 322.784h-.002L74.98 437.019a256.636 256.636 0 0016.435 15.048l97.802-97.804v-31.479zM189.217 189.219v-.002L74.981 74.98a256.636 256.636 0 00-15.048 16.435l97.803 97.803h31.481zM322.783 189.219L437.02 74.981a256.328 256.328 0 00-16.435-15.047l-97.802 97.803v31.482z"></path>
          </g>
        </svg>
      </div>
    </>
  );
};

export default LanguageIcons;
