import React, { createContext, ReactNode, useContext, useState } from "react";

interface IContext {
  snackState: boolean;
  setSnackState: React.Dispatch<React.SetStateAction<boolean>>;
  snackMsg: string;
  setSnackMsg: React.Dispatch<React.SetStateAction<string>>;
}

const SnackContext = createContext({} as IContext);

// useSnackbar hook, used to display success or error messages
export const useSnackbar = () => {
  return useContext(SnackContext);
};

const SnackProvider = ({ children }: { children: ReactNode }) => {
  const [snackState, setSnackState] = useState(false);
  const [snackMsg, setSnackMsg] = useState("");

  return (
    <SnackContext.Provider
      value={{
        snackState,
        setSnackState,
        snackMsg,
        setSnackMsg,
      }}
    >
      {children}
    </SnackContext.Provider>
  );
};

export default SnackProvider;
