import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Jost', sans-serif;
    background-color: #FCFCFC;

    & * {
      box-sizing: border-box;
    }

    .container {
      max-width: 1152px;
      margin: 0 auto;
      padding: 16px;
      /* min-height: 100vh; */
    }
  }
`;

export default GlobalStyle;
