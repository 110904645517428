// import IphoneImage from "../assets/images/iphonex.png";
import HomeInfo from "../components/App/HomeInfo";
import React, { useEffect } from "react";
import styled from "styled-components";
import MediaQuery from "react-responsive";
import WaveBackground from "../components/App/WaveBackground";
import FeaturedCoupons from "../components/App/Coupons/FeaturedCoupons";
import { Typography } from "@material-ui/core";
import MoreInfo from "../components/App/MoreInfo";
import CustomizedDialog from "../components/CustomizedDialog";
import { moveInLeft, moveInRight, moveInTop } from "../styles/keyframes";
import { useTranslation } from "react-i18next/";
import Header from "../components/Header/Header";
import { Helmet } from "react-helmet";

enum MobileOS {
  WINDOWS,
  ANDROID,
  IOS,
  UNKNOWN,
}

declare const window: Window & { opera: string; MSStream: object };

const getMobileOperatingSystem = (): MobileOS => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return MobileOS.WINDOWS;
  }

  if (/android/i.test(userAgent)) {
    return MobileOS.ANDROID;
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return MobileOS.IOS;
  }

  return MobileOS.UNKNOWN;
};

const useMobileOperatingSystem = () => {
  const mobileOS = getMobileOperatingSystem();

  useEffect(() => {
    switch (mobileOS) {
      case MobileOS.IOS:
        window.location.href = "https://apps.apple.com/us/app/icelandic-coupons/id1317769121";
        break;
      case MobileOS.ANDROID:
        window.location.href = "https://play.google.com/store/apps/details?id=com.loftfar.coupons";
        break;
    }
  }, [mobileOS]);

  return mobileOS;
};

const AppPage = () => {
  const { t } = useTranslation();

  useMobileOperatingSystem();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>{t("HelmetAppTitle")}</title>
        <meta name="description" content={t("HelmetAppContent")} />
      </Helmet>

      <Header />
      <WaveBackground c1="#4682e2" c2="#7701fd" />

      <ParentDiv>
        <MainContent>
          <MediaQuery query="(min-width: 1400px)">
            <PhoneWrapper>
              <PhoneImageDivLeft />
              <PhoneImageDiv />
              <PhoneImageDivRight />
            </PhoneWrapper>
          </MediaQuery>

          <MediaQuery query="(max-width: 1399px)">
            <PhoneImageDiv />
          </MediaQuery>
        </MainContent>
        <SubContent>
          <HomeInfo />
        </SubContent>
      </ParentDiv>

      <SecondParentDiv>
        <CouponWrapper>
          <Typography
            variant="h6"
            style={{
              display: "flex",
              marginLeft: "10px",
              position: "relative",
            }}
          >
            {t("AppFeatured")}
          </Typography>
          <FeaturedCoupons />
        </CouponWrapper>
        <MoreInfoWrapper>
          <MoreInfo />
        </MoreInfoWrapper>
      </SecondParentDiv>
      <CustomizedDialog />
    </>
  );
};

const ParentDiv = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100vh;
`;

const MainContent = styled.div`
  display: flex;
  justify-content: center;
  flex: 6;
  padding: 100px 24px 24px 24px;

  @media screen and (min-width: 768px) {
    flex: 4;
  }
`;

const SubContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 3;
  justify-content: center;
  padding-bottom: 12px;

  @media screen and (min-width: 768px) {
    flex: 1;
  }
`;

const PhoneImageDiv = styled.div`
  display: flex;
  background-image: url("https://res.cloudinary.com/dineout/image/upload/v1715073529/couponsweb/uuptlf7anq4g9pzwm7ns.png");
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 100%;
  width: 700px;
  animation: ${moveInTop} 1s;
`;

const PhoneImageDivLeft = styled.div`
  display: flex;
  background-image: url("https://res.cloudinary.com/dineout/image/upload/v1715073529/couponsweb/csbk5eh97gujwb09csmd.png");
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 100%;
  width: 470px;
  animation: ${moveInLeft} 1s;
`;

const PhoneImageDivRight = styled.div`
  display: flex;
  background-image: url("https://res.cloudinary.com/dineout/image/upload/v1715073529/couponsweb/aynejvuut9w3v8gnvfaz.png");
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 100%;
  width: 470px;
  animation: ${moveInRight} 1s;
`;

const MoreInfoWrapper = styled.div`
  margin: 50px 8px;

  @media screen and (min-width: 768px) {
    margin: 120px 8px 35px 8px;
  }
`;

const CouponWrapper = styled.div`
  display: flex;
  max-width: 100%;
  flex-direction: column;
`;

const SecondParentDiv = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1400px;
  margin: 0 auto;
`;

const PhoneWrapper = styled.div`
  display: flex;
  position: relative;
  width: 57vw;
  justify-content: space-around;
`;

export default AppPage;
