import { createTheme } from "@material-ui/core";

export const muiTheme = createTheme({
  typography: {
    fontFamily: "Jost, sans-serif",
  },

  overrides: {
    MuiIconButton: {
      root: {
        // padding: "0px",
      },
    },

    MuiMenu: {
      paper: {
        backgroundColor: "#181818",
        color: "white",
      },
    },
    MuiAppBar: {
      root: {
        // position: "fixed",
        // zIndex: theme.zIndex.drawer + 1,
        zIndex: 1400,
      },

      colorPrimary: {
        // backgroundColor: "transparent",
      },
    },

    MuiDrawer: {
      paper: {
        // marginTop: "80px",
        // backgroundColor: "#2a2a2a",
        // backgroundColor: "#3051e2",
        // backgroundColor: "#3051e2",

        // backgroundColor: "#1c1c1c",
        // backgroundColor: "#3574C7",
        // background: "linear-gradient(to right, rgb(45, 114, 224) 0%, rgb(79, 64, 238) 100%)",
        background: "linear-gradient(to right, rgb(70, 130, 226) 0%, rgb(79, 64, 238) 100%)",

        // opacity: "95%",

        color: "white",
        width: "100%",
        // paddingTop: "24px",
        display: "flex",
        // alignItems: "center",
      },
    },

    MuiTextField: {
      root: {
        width: "100%",
      },
    },
    MuiListItem: {
      root: {
        cursor: "pointer",
        "&:hover": {
          // transition: "transform .3s",
          // transform: "scale(1.15)",
        },
        "&$selected": {
          backgroundColor: "transparent",
          // paddingBottm: "10px",
          // height: "4px",
          "&:hover": {
            // transform: "scale(1.15)",
            backgroundColor: "transparent",
          },
        },
      },
    },

    // FIXES BOOTSTRAP SETTING OUTLINE, because of carousel
    // MuiButton: {
    //   root: {
    //     "&:focus": {
    //       outline: "none",
    //     },
    //   },
    // },
    // MuiCardActionArea: {
    //   root: {
    //     "&:focus": {
    //       outline: "none",
    //     },
    //   },
    // },
  },
});
