import React from "react";
import { useCoupon } from "../../../context/CouponContext";
import CouponListItem from "./CouponListItem";
import ScrollContainer from "react-indiana-drag-scroll";
import MoreCard from "./MoreCard";
import CouponLoadingItem from "./CouponLoadingItem";

const FeaturedCoupons = () => {
  const { couponsData, couponsLoading } = useCoupon();
  const FeaturedCount = 10;

  return (
    <div>
      <ScrollContainer
        nativeMobileScroll
        style={{ display: "flex", position: "relative", maxWidth: "1527px", overflowX: "scroll" }}
      >
        <div style={{ display: "flex", cursor: "grab" }}>
          {couponsLoading
            ? [...Array(FeaturedCount)].map((_, i) => <CouponLoadingItem key={i} />)
            : couponsData?.slice(0, FeaturedCount).map((coupon) => {
                return (
                  <CouponListItem
                    key={coupon.id}
                    coupon={coupon}
                  />
                );
              })}
          <MoreCard />
        </div>
      </ScrollContainer>
    </div>
  );
};

export default FeaturedCoupons;
