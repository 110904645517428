import React, { createContext, ReactNode, useContext, useState } from "react";

interface IContext {
  dialog: boolean;
  setDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

const DialogContext = createContext({} as IContext);

export const useDialog = () => {
  return useContext(DialogContext);
};

const DialogProvider = ({ children }: { children: ReactNode }) => {
  const [dialog, setDialog] = useState(false);

  return <DialogContext.Provider value={{ dialog, setDialog }}>{children}</DialogContext.Provider>;
};

export default DialogProvider;
