import React, { useEffect } from "react";
import { useTranslation } from "react-i18next/";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header/Header";
import AboutPage from "./pages/AboutPage";
import AppPage from "./pages/AppPage";
import BrochurePage from "./pages/BrochurePage";
import CouponsPage from "./pages/CouponsPage";
import StripePage from "./pages/StripePage";

const Layout = () => {
  const { i18n } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    if (history.location.search !== `?lng=${i18n.language}`) {
      history.push(`?lng=${i18n.language}`);
    }
  }, []);

  return (
    <>
      {history.location.pathname.includes("/app") ||
      history.location.pathname.includes("/buy") ||
      history.location.pathname.includes("/stripe") ? null : (
        <Header />
      )}
      <Switch>
        <Route path={["/app/:id", "/app"]} component={AppPage} />
        {/* <Route path={["/brochure/access"]} component={BrochureSubPage} /> */}
        <Route path={["/brochure"]} component={BrochurePage} />
        <Route path={["/coupons"]} component={CouponsPage} />
        <Route path={["/about"]} component={AboutPage} />
        <Route path={["/buy"]} component={StripePage} />
        {/* <Route path={["/stripe"]} component={StripePage} /> */}

        <Redirect from={"/"} to={"/app"} />
      </Switch>
      {history.location.pathname.includes("/buy") || history.location.pathname.includes("/stripe") ? null : <Footer />}
    </>
  );
};

export default Layout;
