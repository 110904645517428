import { keyframes } from "styled-components";

export const moveInTop = keyframes`
    0% {
      opacity: 0;
      transform: translateY(-20vh);
    }
    100% {
      opacity: 1;
      transform: translate(0);
    }
`;

export const moveInLeft = keyframes`
    0% {
      opacity: 0;
      transform: translateX(-20vw);
    }
    100% {
      opacity: 1;
      transform: translate(0);
    }
`;

export const moveInRight = keyframes`
    0% {
      opacity: 0;
      transform: translateX(20vw);
    }
    100% {
      opacity: 1;
      transform: translate(0);
    }
`;
