import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ThemeProvider as StyledComponentsThemeProvider } from "styled-components";
import GlobalStyle from "./styles/GlobalStyle";
import theme from "./styles/theme";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core";
import Layout from "./Layout";
import CouponProvider from "./context/CouponContext";
import { muiTheme } from "./styles/muiTheme";
import DialogProvider from "./context/DialogContext";
import "../src/pages/i18n";
import BurgerProvider from "./context/BurgerContext";
import SnackProvider from "./context/SnackContext";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";

const queryClient = new QueryClient();

const App = () => {
  return (
    <StyledComponentsThemeProvider theme={theme}>
      <MuiThemeProvider theme={muiTheme}>
        <QueryClientProvider client={queryClient}>
          <DialogProvider>
            <BurgerProvider>
              <CouponProvider>
                <SnackProvider>
                  <BrowserRouter>
                    <GlobalStyle />
                    <Switch>
                      <Route path="/" component={Layout} />
                    </Switch>
                  </BrowserRouter>
                </SnackProvider>
              </CouponProvider>
            </BurgerProvider>
          </DialogProvider>
        </QueryClientProvider>
      </MuiThemeProvider>
    </StyledComponentsThemeProvider>
  );
};

export default App;
