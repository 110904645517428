import {
  CompanyLanguageModel,
  CompanyLocationViewModel,
  CouponLanguageModel,
  DiscountModel,
} from "../types/graphTypes";
import { useTranslation } from "react-i18next/";

export const discountString = (discount?: DiscountModel): string => {
  switch (discount?.type) {
    case "FLAT_FEE":
      return `${discount.flatFee} kr.`;
    case "PERCENTAGE":
      return `${discount.percentage}%`;
    case "TWO_FOR_ONE":
      return "2f1";
    default:
      return "";
  }
};

export const locationsString = (locations: Array<CompanyLocationViewModel>): string => {
  const { t } = useTranslation();

  if (locations.length === 1) {
    return `${locations[0].zipCode} ${locations[0].cityName}`;
  } else if (locations.length === 2) {
    if (locations[0].cityName === locations[1].cityName) {
      return `${locations[0].zipCode} ${locations[0].cityName}`;
    } else {
      return `${locations[0].zipCode} ${locations[0].cityName} & ${locations[1].zipCode} ${locations[1].cityName}`;
    }
  } else if (locations.length > 2) {
    return `${locations.length} ${t("locations")}`;
  } else {
    return "";
  }
};

export const descriptionString = (languages: CouponLanguageModel[]): string => {
  const { i18n } = useTranslation();

  if (languages) {
    return languages.find((l) => l.language === i18n.language)?.description || "";
  }

  return "";
};

export const companyNameString = (languages: CompanyLanguageModel[]): string => {
  const { i18n } = useTranslation();

  if (languages) {
    return languages.find((l) => l.language === i18n.language)?.name || "";
  }

  return "";
};
