import React from "react";

interface IProps {
  c1: string;
  c2: string;
}

const WaveBackground = (props: IProps) => {
  const { c1, c2 } = props;
  return (
    <div style={{ width: "1000px" }}>
      <svg
        style={{
          position: "absolute",
        }}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 490 320"
      >
        <defs>
          <linearGradient id="gradient">
            {/* <stop offset="5%" stopColor="#2d72e0" />
            <stop offset="70%" stopColor="#7701fd" /> */}
            <stop offset="5%" stopColor={c1} />
            <stop offset="70%" stopColor={c2} />
            {/* <stop offset="5%" stopColor="#619CFF" />
            <stop offset="70%" stopColor="#5f09ff" /> */}
          </linearGradient>
        </defs>
        <path
          style={{ position: "absolute" }}
          fill="url(#gradient)"
          fillOpacity="1"
          d="M0,224L26.7,208C53.3,192,107,160,160,154.7C213.3,149,267,171,320,186.7C373.3,203,427,213,480,197.3C533.3,181,587,139,640,106.7C693.3,75,747,53,800,69.3C853.3,85,907,139,960,176C1013.3,213,1067,235,1120,208C1173.3,181,1227,107,1280,85.3C1333.3,64,1387,96,1413,112L1440,128L1440,0L1413.3,0C1386.7,0,1333,0,1280,0C1226.7,0,1173,0,1120,0C1066.7,0,1013,0,960,0C906.7,0,853,0,800,0C746.7,0,693,0,640,0C586.7,0,533,0,480,0C426.7,0,373,0,320,0C266.7,0,213,0,160,0C106.7,0,53,0,27,0L0,0Z"
        ></path>
      </svg>
    </div>
  );
};

export default WaveBackground;

// import React from "react";

// const WaveBackground = () => {
//   return (
//     <div style={{ width: "1000px" }}>
//       <svg
//         style={{
//           position: "absolute",
//         }}
//         xmlns="http://www.w3.org/2000/svg"
//         viewBox="0 0 490 320"
//       >
//         <path
//           style={{ position: "absolute" }}
//           fill="#3C6CED"
//           fill-opacity="1"
//           d="M0,224L26.7,208C53.3,192,107,160,160,154.7C213.3,149,267,171,320,186.7C373.3,203,427,213,480,197.3C533.3,181,587,139,640,106.7C693.3,75,747,53,800,69.3C853.3,85,907,139,960,176C1013.3,213,1067,235,1120,208C1173.3,181,1227,107,1280,85.3C1333.3,64,1387,96,1413,112L1440,128L1440,0L1413.3,0C1386.7,0,1333,0,1280,0C1226.7,0,1173,0,1120,0C1066.7,0,1013,0,960,0C906.7,0,853,0,800,0C746.7,0,693,0,640,0C586.7,0,533,0,480,0C426.7,0,373,0,320,0C266.7,0,213,0,160,0C106.7,0,53,0,27,0L0,0Z"
//         ></path>
//       </svg>
//     </div>
//   );
// };

// export default WaveBackground;
